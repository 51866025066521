import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type UserNameProps = {
  name: string;
};
export const initialState: UserNameProps = {
  name: '',
};

const reportNameSlice = createSlice({
  name: 'reportName',
  initialState,
  reducers: {
    onChangeName: (state: UserNameProps, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
  },
});

export default reportNameSlice;
