/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo, useCallback, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { RootState } from '@reducer/store';
import sidebarSlice, { NaviItemProps } from '@reducer/common/sidebar';

import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';
import caramellaLogo from '@static/images/caramellaLogo.png';

function Sidebar() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const sideMenu = useSelector((state: RootState) => state.sidebar.list);

  const onClick = useCallback(
    (item: number) => () => {
      dispatch(sidebarSlice.actions.onIsActive(item));
    },
    [dispatch, sidebarSlice],
  );

  useEffect(() => {
    const activeTab =
      ['/action', '/payment', '/charge', '/usage', '/refund'].indexOf(pathname) > -1
        ? '/action'
        : ['/search', '/search/detail'].indexOf(pathname) > -1
        ? '/search'
        : pathname.indexOf('qna') > -1
        ? '/qna'
        : pathname.indexOf('notice') > -1
        ? '/notice'
        : pathname;

    const Active = sideMenu.find((el) => el.linkName === activeTab) as NaviItemProps;
    if (Active) dispatch(sidebarSlice.actions.onIsActive(Active.id));
  }, [dispatch, sidebarSlice, pathname]);

  return (
    <MenuBar>
      <Logo>
        <Link to='/main'>
          <img src={caramellaLogo} alt='caramella logo' />
        </Link>
      </Logo>
      <MenuList>
        {sideMenu.map(({ id, icon: Icon, linkName, menuName, isActive }) => (
          <li key={id} className={isActive ? 'active' : ''} onClick={() => onClick(id)}>
            <Icon />
            <Link to={linkName}>{menuName}</Link>
          </li>
        ))}
      </MenuList>
    </MenuBar>
  );
}

const MenuBar = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  flex: none;
  background-color: ${theme.background.white};
  border-right: 1px solid ${theme.border.gray};
`;
const Logo = styled.div`
  text-align: center;
  padding: 30px 0;
  border-bottom: 1px solid ${theme.border.gray};
  a {
    display: inline-block;
    width: ${pixelToRem(190)};
    height: ${pixelToRem(18)};
  }
  img {
    width: 100%;
    height: 100%;
  }
`;
const MenuList = styled.ul`
  padding: ${pixelToRem(30)};
  li {
    cursor: pointer;
    a {
      padding: ${pixelToRem(15)};
    }
    &.active a {
      color: ${theme.color.mainColor};
      font-weight: bold;
    }
  }
  li svg {
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
  }
  li a {
    display: inline-block;
    vertical-align: middle;
    color: ${theme.color.gray808285};
    font-size: ${pixelToRem(16)};
  }
`;

export default memo(Sidebar);
