import React, { memo } from 'react';

import { FooterWrap, FooterInfo } from '@src/layout';

function Footer() {
  return (
    <FooterWrap>
      <FooterInfo>
        <li>(주) 카라멜라</li>
        <li>대표 : 박서연</li>
        <li>사업자등록번호 : 405-86-01678</li>
        <li>광주광역시 동구 금남로 193-12, 702호</li>
        <li>고객센터 : 062-714-3626</li>
      </FooterInfo>
      <FooterInfo>
        <li>개인정보 처리방침</li>
        <li>서비스 이용약관</li>
      </FooterInfo>
    </FooterWrap>
  );
}

export default memo(Footer);
