import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type InputDataState = {
  inputData: string;
};
export const initialState: InputDataState = {
  inputData: '',
};

const inputDataSlice = createSlice({
  name: 'inputData',
  initialState,
  reducers: {
    onSetName: (state: InputDataState, action: PayloadAction<string>) => {
      state.inputData = action.payload;
    },
    onReset: (state) => {
      state.inputData = '';
    },
  },
});

export default inputDataSlice;
