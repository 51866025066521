import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { ActionListType } from '@comp/action/ActionTypes';

export type DataListProps = {
  list: ActionListType;
};
export const initialState: DataListProps = {
  list: [],
};

const actionListSlice = createSlice({
  name: 'actionList',
  initialState,
  reducers: {
    setIsRefund: (state, action: PayloadAction<{ idx: number; isRefund: -1 | 0 | 1 }>) => {
      state.list[action.payload.idx].isRefund = action.payload.isRefund;
    },
    setDataList: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setReset: (state) => {
      state.list = [];
    },
  },
});

export default actionListSlice;
