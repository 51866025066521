import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';

import CustomInput from '@comp/common/CustomInput';
import Button from '@comp/common/Button';

import { RootState } from '@reducer/index';
import inputDataSlice from '@reducer/common/inputData';
import userPhoneSlice from '@reducer/user/userPhoneInput';
import userBirthSlice from '@reducer/user/userBirthInput';
import detailFilterSlice from '@reducer/user/detailFilter';
import type { FilterType } from '@reducer/user/detailFilter';

export type HasContentProps = {
  hasInput?: boolean;
  hasTab?: boolean;
};
type FilterStyleProps = {
  margin?: string;
};

function SearchFilter({ hasInput = false, hasTab = false }: HasContentProps) {
  const dispatch = useDispatch();

  const name = useSelector((state: RootState) => state.inputData.inputData);
  const phone = useSelector((state: RootState) => state.phoneInput.phone);
  const birth = useSelector((state: RootState) => state.birthInput.birth);
  const detailFilter = useSelector((state: RootState) => state.detailFilter);

  const onIsActive = useCallback(
    (type: keyof FilterType, tab: string) => {
      dispatch(detailFilterSlice.actions.setVerfied({ type, tab }));
    },
    [dispatch, detailFilterSlice],
  );
  const onChangeName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(inputDataSlice.actions.onSetName(e.target.value));
    },
    [dispatch, inputDataSlice],
  );

  const onChangeNumber = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(userPhoneSlice.actions.onSetPhone(e.target.value));
    },
    [dispatch, inputDataSlice],
  );

  const onChangeBirth = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(userBirthSlice.actions.onSetBirth(e.target.value));
    },
    [dispatch, inputDataSlice],
  );

  return (
    <SearchTable>
      {hasInput && (
        <InputArea>
          <TableRow margin={`0 0 ${pixelToRem(10)} 0`}>
            <Thead>이름</Thead>
            <Tbody>
              <CustomInput
                width={260}
                padding={`${pixelToRem(10)} ${pixelToRem(20)}`}
                radius={0}
                placeholder='홍길동'
                value={name}
                onChange={onChangeName}
              />
            </Tbody>
          </TableRow>
          <TableRow margin={`0 0 ${pixelToRem(10)} 0`}>
            <Thead>전화번호</Thead>
            <Tbody>
              <CustomInput
                width={260}
                padding={`${pixelToRem(10)} ${pixelToRem(20)}`}
                radius={0}
                placeholder='000-0000-0000'
                value={phone}
                onChange={onChangeNumber}
              />
            </Tbody>
          </TableRow>
          <TableRow margin={`0 0 ${pixelToRem(25)} 0`}>
            <Thead>생년월일</Thead>
            <Tbody>
              <CustomInput
                width={260}
                padding={`${pixelToRem(10)} ${pixelToRem(20)}`}
                radius={0}
                placeholder='19900101'
                value={birth}
                onChange={onChangeBirth}
              />
            </Tbody>
          </TableRow>
        </InputArea>
      )}
      {hasTab && (
        <>
          {(Object.keys(detailFilter) as Array<keyof typeof detailFilter>).map((key) => (
            <TableRow key={`user-detail-${key}`} margin={`0 0 ${pixelToRem(10)} 0`}>
              <Thead>{detailFilter[key].title}</Thead>
              <Tbody>
                <Button
                  margin={`0 ${pixelToRem(3)} 0 0`}
                  fontColor={`${theme.color.black}`}
                  padding={`${pixelToRem(11)} 0`}
                  width={85}
                  borderColor={`1px solid ${theme.border.gray}`}
                  isHover={true}
                  hoverBgColor={theme.background.mainColor}
                  hoverColor={theme.color.white}
                  hoverBorder={`1px solid ${theme.border.mainColor}`}
                  onClick={() => onIsActive(key, detailFilter[key].firstTab)}
                  isActiveBtn={!!(detailFilter[key].isActive === detailFilter[key].firstTab)}
                >
                  {detailFilter[key].firstTab}
                </Button>
                <Button
                  margin={`0 ${pixelToRem(3)} 0 0`}
                  fontColor={`${theme.color.black}`}
                  padding={`${pixelToRem(11)} 0`}
                  width={85}
                  borderColor={`1px solid ${theme.border.gray}`}
                  isHover={true}
                  hoverBgColor={theme.background.mainColor}
                  hoverColor={theme.color.white}
                  hoverBorder={`1px solid ${theme.border.mainColor}`}
                  onClick={() => onIsActive(key, detailFilter[key].lastTab)}
                  isActiveBtn={!!(detailFilter[key].isActive === detailFilter[key].lastTab)}
                >
                  {detailFilter[key].lastTab}
                </Button>
              </Tbody>
            </TableRow>
          ))}
        </>
      )}
    </SearchTable>
  );
}
const SearchTable = styled.div`
  width: 100%;
`;
const InputArea = styled.div`
  border-bottom: 1px solid ${theme.border.gray};
`;
const TableRow = styled.div<FilterStyleProps>`
  font-size: 0;
  margin: ${({ margin }) => margin ?? '0'};
`;
const Thead = styled.p`
  font-size: ${pixelToRem(14)};
  color: ${theme.color.gray707070};
  display: inline-block;
  vertical-align: middle;
  width: ${pixelToRem(100)};
  font-weight: 600;
`;
const Tbody = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - ${pixelToRem(100)});
  input {
    border: 1px solid ${theme.border.gray};
    padding: ${pixelToRem(10)} ${pixelToRem(20)};
    font-size: ${pixelToRem(16)};
  }
  p {
    font-size: ${pixelToRem(20)};
  }
`;
export default memo(SearchFilter);
