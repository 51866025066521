module.exports = {
  name: 'calendar_ko',
  months: [
    ['1월', '1'],
    ['2월', '2'],
    ['3월', '3'],
    ['4월', '4'],
    ['5월', '5'],
    ['6월', '6'],
    ['7월', '7'],
    ['8월', '8'],
    ['9월', '9'],
    ['10월', '10'],
    ['11월', '11'],
    ['12월', '12'],
  ],
  weekDays: [
    ['토요일', '토'],
    ['일요일', '일'],
    ['월요일', '월'],
    ['화요일', '화'],
    ['수요일', '수'],
    ['목요일', '목'],
    ['금요일', '금'],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  meridiems: [
    ['오전', '오전'],
    ['오후', '오후'],
  ],
};
