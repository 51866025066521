import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import {
  AiOutlineAppstore,
  AiOutlineFileSearch,
  AiOutlineTeam,
  AiOutlineMessage,
  AiOutlineNotification,
} from 'react-icons/ai';
import { IconType } from 'react-icons';
import { FiAlertOctagon } from 'react-icons/fi';
import { BiCoinStack } from 'react-icons/bi';

export type NaviItemProps = {
  id: number;
  icon: IconType;
  isActive: boolean;
  linkName: string;
  menuName: string;
};
export type InitialState = {
  list: NaviItemProps[];
};
export const initialState: InitialState = {
  list: [
    {
      id: 0,
      icon: AiOutlineAppstore,
      isActive: false,
      linkName: '/main',
      menuName: '대시보드',
    },
    {
      id: 1,
      icon: AiOutlineFileSearch,
      isActive: false,
      linkName: '/search',
      menuName: '검색 관리',
    },
    {
      id: 2,
      icon: FiAlertOctagon,
      isActive: false,
      linkName: '/report',
      menuName: '신고 관리',
    },
    {
      id: 3,
      icon: BiCoinStack,
      isActive: false,
      linkName: '/action',
      menuName: '이용 관리',
    },
    {
      id: 4,
      icon: AiOutlineTeam,
      isActive: false,
      linkName: '/user',
      menuName: '유저 관리',
    },
    {
      id: 5,
      icon: AiOutlineMessage,
      isActive: false,
      linkName: '/qna',
      menuName: '문의 관리',
    },
    {
      id: 6,
      icon: AiOutlineNotification,
      isActive: false,
      linkName: '/notice',
      menuName: '공지사항',
    },
  ],
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    onIsActive: (state: InitialState, action: PayloadAction<number>) => {
      state.list = state.list.map((x) => {
        return {
          ...x,
          isActive: x.id === action.payload,
        };
      });
    },
  },
});

export default sidebarSlice;
