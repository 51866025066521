/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';

import useModal from '@hook/useModal';

import { IoMdClose } from 'react-icons/io';

import { withEdit, BasePropsType } from '@comp/common/wrapper/EditWrapper';
import PageTitle from '@comp/common/PageTitle';
import FooterButton from '@comp/common/FooterButton';
import { StyledButton, FileButton } from '@comp/common/StyledButton';
import Row from '@comp/common/Row';
import Modal from '@comp/common/modal/Modal';
import { initialNoticeDetail, NoticeDetailType } from '@comp/notice/NoticeTypes';

import { PageTitleWrap } from '@src/layout';

import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';
import { getFileList } from '@util/commonUtil';
import { PostFileType } from '@util/initialData';

enum noticeCategory {
  NORMAL = '일반',
  SYSTEM = '시스템',
}

function Base(props: BasePropsType<NoticeDetailType>) {
  const { data, id, type, onClickCancel, onClickList, onClickToCreate, onClickToModify } = props;

  const [category, setCategory] = useState('NORMAL');
  const [title, setTitle] = useState(initialNoticeDetail.notice.title);
  const [content, setContent] = useState(initialNoticeDetail.notice.content);
  const [files, setFiles] = useState<PostFileType[]>([]);
  const [fileList, setFileList] = useState<{ dataUri: string; filename: string }[]>([]);
  const [modalType, setModalType] = useState(type);
  const { isShow, onShow, onNoShow } = useModal();

  useEffect(() => {
    if (data) {
      const { category, title, content, postFiles } = data.notice;
      setCategory(category || 'NORMAL');
      setTitle(title);
      setContent(content);
      if (postFiles) {
        setFiles(postFiles);
      }
    }
  }, [data]);

  useEffect(() => {
    const newFileList = getFileList(files);
    setFileList(newFileList);
  }, [files]);

  const onChangeTitle = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  }, []);

  const onChangeContent = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.target.value);
  }, []);

  const checkFile = useCallback(
    (file: File) => {
      return (
        !files.find((existFile: File) => {
          return (
            existFile.name === file.name &&
            existFile.lastModified === file.lastModified &&
            existFile.size === file.size &&
            existFile.type === file.type
          );
        }) &&
        file.size < 100 * 1024 * 1024 &&
        file.size > 0
      );
    },
    [files],
  );

  const onChangeFileSelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { files: selected } = e.target;
      if (selected && checkFile(selected[0])) {
        setFiles([...files, selected[0]]);
      }
    },
    [files],
  );

  const onClickFileDelete = useCallback(
    (index: number) => {
      setFiles([...files.slice(0, index), ...files.slice(index + 1)]);
    },
    [files],
  );

  const onClickCategoryButton = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    const target = e.target as HTMLButtonElement;
    setCategory(target.value);
  }, []);

  const onClickUpdateButton = useCallback(() => {
    setModalType(type);
    onShow();
  }, []);

  const onClickCancelButton = useCallback(() => {
    setModalType('cancel');
    onShow();
  }, []);

  const modalObj = {
    'notice/create': {
      h1Text: '공지사항 작성글을 등록하시겠습니까?',
      firstBtn: {
        isDisplay: true,
        content: '확인',
        onClick: () => {
          setModalType('loading');
          onClickToCreate({
            category,
            title,
            content,
            fileList,
          });
        },
      },
    },
    'notice/modify': {
      h1Text: '공지사항을 수정하시겠습니까?',
      firstBtn: {
        isDisplay: true,
        content: '확인',
        onClick: () => {
          setModalType('loading');
          onClickToModify({
            id,
            category,
            title,
            content,
            fileList,
          });
        },
      },
    },
    loading: {
      h1Text: '잠시 기다려 주세요',
      firstBtn: { isDisplay: false },
      secondBtn: { isDisplay: false },
    },
    cancel: {
      h1Text: '공지사항 작성글을 취소하시겠습니까?',
      firstBtn: {
        isDisplay: true,
        content: '확인',
        onClick: onClickCancel,
      },
    },
  };

  return (
    <>
      <Modal
        isShow={isShow}
        secondBtn={{ isDisplay: true, content: '취소', onClick: onNoShow }}
        {...modalObj[modalType as keyof typeof modalObj]}
      ></Modal>
      <PageTitleWrap>
        <PageTitle>{type === 'notice/create' ? '공지사항 등록' : '공지사항 수정'}</PageTitle>
        <EditHeader>
          <Row title='구분'>
            {Object.keys(noticeCategory).map((el) => (
              <StyledButton
                key={el}
                type={category === el ? 'submit' : 'button'}
                fontSize={13}
                width={85}
                value={el}
                onClick={onClickCategoryButton}
              >
                {noticeCategory[el as keyof typeof noticeCategory]}
              </StyledButton>
            ))}
          </Row>
          <Row title='제목'>
            <EditTitle value={title} onChange={onChangeTitle} />
          </Row>
        </EditHeader>
        <EditBody>
          <Row title='내용'>
            <EditContent value={content} onChange={onChangeContent} />
          </Row>
          <Row title='첨부파일'>
            <UploadFileButtonLabel htmlFor='upload'>
              <div>파일선택</div>
            </UploadFileButtonLabel>
            <UploadFileButton type='file' id='upload' onChange={onChangeFileSelect} />
            {files.map((file, index) => {
              return (
                <FileButton key={file.name + index} type='submit'>
                  {file.name} <IoMdClose size={12} onClick={() => onClickFileDelete(index)} />
                </FileButton>
              );
            })}
          </Row>
          <Row title=''>
            <PostFileDesc>* 100MB이하 파일만 업로드 가능</PostFileDesc>
          </Row>
        </EditBody>
        <FooterButton
          list={{ type: 'button', text: '목록', onClick: onClickList }}
          submit={{
            type: 'submit',
            text: type === 'notice/create' ? '등록' : '수정',
            onClick: onClickUpdateButton,
          }}
          cancel={{ type: 'button', text: '취소', onClick: onClickCancelButton }}
        />
      </PageTitleWrap>
    </>
  );
}

export default function NoticeEdit() {
  return withEdit<NoticeDetailType>(Base);
}

const EditHeader = styled.div`
  width: 100%;
  padding: ${pixelToRem(15)} 0 ${pixelToRem(9)} 0;
  display: flex;
  flex-direction: column;
  gap: ${pixelToRem(8)};
`;

const EditTitle = styled.input`
  width: 100%;
  height: ${pixelToRem(40)};
  padding: 0 ${pixelToRem(20)};
  border: 1px solid ${theme.border.gray};
  font-size: ${pixelToRem(13)};
  color: ${theme.color.blue};
`;

const EditBody = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${theme.border.gray};

  & > div {
    margin-bottom: ${pixelToRem(20)};
  }

  & > div:nth-child(2) {
    margin-bottom: ${pixelToRem(9)};
  }
`;

const EditContent = styled.textarea`
  width: 100%;
  height: 380px;
  resize: none;
  padding: ${pixelToRem(13)} ${pixelToRem(20)} 0 ${pixelToRem(20)};
  border: 1px solid ${theme.border.gray};
  line-height: ${pixelToRem(20)};
  font-size: ${pixelToRem(13)};
`;

const UploadFileButtonLabel = styled.label`
  margin-right: ${pixelToRem(9)};

  div {
    width: ${pixelToRem(113)};
    height: ${pixelToRem(40)};
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.background.mainColor};
    font-size: ${pixelToRem(13)};
    font-weight: 500;
    color: ${theme.color.white};
    cursor: pointer;
  }
`;

const UploadFileButton = styled.input`
  display: none;
`;

const PostFileDesc = styled.div`
  color: ${theme.color.gray707070};
`;
