import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '@comp/common/Button';
import DatePicker from '@comp/common/calendar/DatePicker';

import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';

import type { RootState } from '@reducer/store';
import periodFilterSlice from '@/src/reducers/common/periodFilter';
import isSearchSlice from '@reducer/common/isSearch';

function PeriodFilter() {
  const dispatch = useDispatch();
  const { periodName } = useSelector((state: RootState) => state.periodFilter);

  const periodList = ['오늘', '1주일', '1개월', '3개월'];

  const onChangeDate = useCallback(
    (i: string) => {
      dispatch(periodFilterSlice.actions.onUpdate({ periodName: i, startDate: '', endDate: '' }));
      dispatch(isSearchSlice.actions.onSearch(false));
    },
    [dispatch, periodFilterSlice, isSearchSlice],
  );
  useEffect(() => {
    dispatch(periodFilterSlice.actions.onReset());
  }, []);

  return (
    <>
      <PeriodTab>
        {periodList.map((i, index) => (
          <TabWrpper key={index}>
            <Button
              fontColor={`${theme.color.black}`}
              fontSize={15}
              padding={`${pixelToRem(11)} 0`}
              width={85}
              borderColor={'none'}
              onClick={() => onChangeDate(i)}
              isActiveBtn={periodName === i}
            >
              {i}
            </Button>
          </TabWrpper>
        ))}
      </PeriodTab>
      <DatePicker />
    </>
  );
}
const PeriodTab = styled.ul`
  display: inline-block;
  vertical-align: middle;
  margin-right: ${pixelToRem(20)};
  font-size: 0;
`;
const TabWrpper = styled.li`
  display: inline-block;
  vertical-align: middle;
  border: 1px solid ${theme.border.gray};
  border-right: none;
  &:last-child {
    border-right: 1px solid ${theme.border.gray};
  }
`;
export default memo(PeriodFilter);
