import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { withList, BasePropsType } from '@comp/common/wrapper/ListWrapper';
import PageTitle from '@comp/common/PageTitle';
import FilterItem from '@comp/common/FilterItem';
import FilterButton from '@comp/common/FilterButton';
import Table from '@comp/common/table/Table';
import Pagination from '@comp/common/Pagination';
import SearchListTableData from '@comp/search/SearchListTableData';
import { SearchListType, initialSearchList } from '@comp/search/SearchTypes';

import { RootState } from '@reducer/store';

import { ListLayout, FilterBox, InnerList, PageTitleWrap, SectionTitle } from '@src/layout';

import { pixelToRem } from '@util/commonStyles';
import { todayString } from '@util/string';

function Base(props: BasePropsType<SearchListType>) {
  const { data, page, setQuery, onClickSearch, onClickReset, onClickPage } = props;

  const name = useSelector((state: RootState) => state.inputData.inputData);
  const { startDate, endDate } = useSelector((state: RootState) => state.periodFilter);

  const query = {
    name: name.trim(),
    startdate: startDate,
    enddate: endDate || todayString,
    page: `${page}`,
  };

  useEffect(() => {
    setQuery(query);
  }, [name, startDate, endDate, page]);

  const searchList = data || initialSearchList;

  return (
    <>
      <PageTitleWrap>
        <PageTitle />
        <FilterBox>
          <FilterItem
            hasInput
            theadText='사용자'
            width={260}
            margin={`0 0 ${pixelToRem(10)} 0`}
            radius={0}
          />
          <FilterItem theadText='조회기간' hasPeriod margin={`0 0 ${pixelToRem(10)} 0`} />
        </FilterBox>
        <FilterButton onClickSearch={onClickSearch} onClickReset={onClickReset} />
      </PageTitleWrap>
      <ListLayout>
        <SectionTitle>
          전체 검색 목록 <span>({searchList.totalCount.toLocaleString('ko')}건)</span>
        </SectionTitle>
        <InnerList>
          <Table>
            <SearchListTableData faces={searchList.faces} />
          </Table>
          <Pagination totalCount={searchList.totalCount} page={page} setPage={onClickPage} />
        </InnerList>
      </ListLayout>
    </>
  );
}

export default function SearchList() {
  return withList<SearchListType>(Base);
}
