import React, { memo } from 'react';
import styled from 'styled-components';

type ButtonContainerStyleProps = {
  padding?: string;
  display?: string;
  justifyContent?: string;
  align?: string;
  children?: React.ReactNode;
  border?: string;
};

export const Container = styled.div<ButtonContainerStyleProps>`
  padding: ${({ padding }) => padding ?? '0'};
  display: ${({ display }) => display ?? 'block'};
  justify-content: ${({ justifyContent }) => justifyContent ?? 'flex-start'};
  text-align: ${({ align }) => align ?? 'left'};
  border-top: ${({ border }) => border ?? 'none'};
`;

function ButtonContainer({
  padding,
  display,
  justifyContent,
  align,
  children,
  border,
}: ButtonContainerStyleProps) {
  return (
    <Container
      padding={padding}
      display={display}
      justifyContent={justifyContent}
      align={align}
      border={border}
    >
      {children ?? null}
    </Container>
  );
}

export default memo(ButtonContainer);
