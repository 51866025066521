import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type FilterType = {
  isVerified: string;
  idCard: string;
  isBlocked: string;
};

export type FilterListState = {
  [key in keyof FilterType]: {
    title: string;
    firstTab: string;
    lastTab: string;
    isActive: string;
  };
};

const initialState: FilterListState = {
  isVerified: {
    title: '최초 인증 여부',
    firstTab: '인증',
    lastTab: '미인증',
    isActive: '',
  },
  idCard: {
    title: '신분증 등록 여부',
    firstTab: '등록',
    lastTab: '미등록',
    isActive: '',
  },
  isBlocked: {
    title: '차단 여부',
    firstTab: '차단',
    lastTab: '미차단',
    isActive: '',
  },
};

const detailFilterSlice = createSlice({
  name: 'detailFilter',
  initialState,
  reducers: {
    setVerfied: (state, action: PayloadAction<{ type: keyof FilterType; tab: string }>) => {
      state[action.payload.type].isActive = action.payload.tab;
    },
    setReset: (state) => {
      state.isVerified = {
        title: '최초 인증 여부',
        firstTab: '인증',
        lastTab: '미인증',
        isActive: '',
      };
      state.idCard = {
        title: '신분증 등록 여부',
        firstTab: '등록',
        lastTab: '미등록',
        isActive: '',
      };
      state.isBlocked = {
        title: '차단 여부',
        firstTab: '차단',
        lastTab: '미차단',
        isActive: '',
      };
    },
  },
});

export default detailFilterSlice;
