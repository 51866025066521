type TabListProps = {
  id: number;
  tabName: string;
  tab: string;
};

export type TabPathProps = {
  [property: string | number]: TabListProps[];
};

export const TabList: TabPathProps = {
  action: [
    {
      id: 0,
      tabName: '전체',
      tab: 'action',
    },
    {
      id: 1,
      tabName: '결제',
      tab: 'payment',
    },
    {
      id: 2,
      tabName: '충전',
      tab: 'charge',
    },
    {
      id: 3,
      tabName: '소진',
      tab: 'usage',
    },
    {
      id: 4,
      tabName: '환불',
      tab: 'refund',
    },
  ],
  report: [
    {
      id: 0,
      tabName: '전체',
      tab: 'all',
    },
    {
      id: 1,
      tabName: '신고요청',
      tab: 'isReported',
    },
    {
      id: 2,
      tabName: '신고완료',
      tab: 'reported',
    },
  ],
  qna: [
    {
      id: 0,
      tabName: '전체',
      tab: 'all',
    },
    {
      id: 1,
      tabName: '답변',
      tab: 'answered',
    },
    {
      id: 2,
      tabName: '미답변',
      tab: 'notAnswered',
    },
  ],
  notice: [
    {
      id: 0,
      tabName: '전체',
      tab: 'all',
    },
    {
      id: 1,
      tabName: '일반',
      tab: 'normal',
    },
    {
      id: 2,
      tabName: '시스템',
      tab: 'system',
    },
  ],
};
