import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const today = dayjs(new Date()).format('YYYY-MM-DD');

export type PeriodFilterType = {
  periodName: string;
  startDate: string;
  endDate?: string;
};

export const initialState: PeriodFilterType = {
  periodName: '오늘',
  startDate: today,
  endDate: today,
};

const periodFilterSlice = createSlice({
  name: 'periodFilter',
  initialState,
  reducers: {
    onUpdate: (state, action: PayloadAction<PeriodFilterType>) => {
      const { periodName } = action.payload;
      state.periodName = periodName;
      if (action.payload.startDate && action.payload.endDate) {
        state.startDate = action.payload.startDate;
        if (action.payload.endDate) state.endDate = action.payload.endDate;
      } else {
        switch (periodName) {
          case '1주일':
            state.startDate = dayjs(new Date()).add(-6, 'day').format('YYYY-MM-DD');
            break;
          case '1개월':
            state.startDate = dayjs(new Date()).add(-1, 'month').format('YYYY-MM-DD');
            break;
          case '3개월':
            state.startDate = dayjs(new Date()).add(-3, 'month').format('YYYY-MM-DD');
            break;
          default:
            state.startDate = today;
            break;
        }
        state.endDate = today;
      }
    },
    onReset: (state) => {
      state.periodName = '오늘';
      state.startDate = today;
      state.endDate = today;
    },
  },
});

export default periodFilterSlice;
