import React from 'react';
import styled from 'styled-components';
import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';

interface BoxProps {
  width?: number;
  height?: number;
  children?: React.ReactNode;
}

export default function Box({ width, height, children }: BoxProps) {
  return (
    <Wrap width={width} height={height}>
      {children ?? null}
    </Wrap>
  );
}

const Wrap = styled.div<BoxProps>`
  width: ${({ width }) => (width ? pixelToRem(width) : '100%')};
  height: ${({ height }) => (height ? pixelToRem(height) : '100%')};
  padding: 0 ${pixelToRem(20)};
  display: flex;
  align-items: center;
  border: 1px solid ${theme.border.gray};
  font-size: ${pixelToRem(13)};
`;
