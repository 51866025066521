export function queryToString(query: Record<string, any>): string {
  if (!query || Object.keys(query).length === 0) return '';
  return Object.keys(query)
    .map((key) => `${key}=${query[key]}`)
    .join('&');
}

export function stringToQuery(str: string): Record<string, any> {
  const query: Record<string, any> = {};
  if (!str) return {};
  const tmpStr = decodeURIComponent(str).replace('?', '');
  const strList = tmpStr.split('&');
  strList.forEach((item: string) => {
    const [key, value] = item.split('=');
    query[key] = value;
  });
  return query;
}

export function getUrlString(searchParams: URLSearchParams, baseString: string) {
  let urlString = `${baseString}?`;
  searchParams.forEach((value, key) => {
    if (!value.includes('action')) {
      urlString += `${key}=${value}&`;
    }
  });
  urlString = urlString.slice(0, -1);
  return urlString;
}

export function getFilterString(searchParams: URLSearchParams) {
  let filterString = '';
  searchParams.forEach((value, key) => {
    if (key !== 'page') {
      filterString += `&${key}=${value}`;
    }
  });
  return filterString;
}

export function getUrlPath(baseString: string) {
  let urlString = `${baseString}?`;
  urlString = urlString.slice(0, -1);
  return urlString;
}

export const todayString = new Date().toISOString().slice(0, 10);
