import { useQuery, useMutation, QueryKey, useQueryClient } from '@tanstack/react-query';

import $axios from '@config/axios';
import { dataURLtoFile } from '@util/commonUtil';

export const fetcher = async ({ queryKey }: any) => {
  const [url, method] = queryKey;
  const { data } = await $axios({ url, method });
  return data;
};

function useFetcher<T = any, E = any>(
  url: string,
  onCallback?: () => void,
  enabled = true,
): { isLoading: boolean; isError: boolean; data: T | undefined; error: E | null } {
  const c = useQueryClient();
  const key = [url, 'get'];
  const { isLoading, isError, data, error } = useQuery<T, E, T, QueryKey>(key, fetcher, {
    enabled,
    cacheTime: 30000,
    onSuccess: (res) => {
      c.setQueryData(key, () => {
        if (onCallback) onCallback();
        return res;
      });
    },
    onError: () => {
      console.log('ERROR');
    },
  });
  return { isLoading, isError, data, error };
}

const postFetcher = async (
  url: string,
  body: { [key: string]: any },
  onSuccess?: () => void,
  onError?: () => void,
) => {
  const formData = new FormData();
  Object.keys(body).forEach((key) => {
    if (key === 'fileList') {
      body.fileList.forEach((file: { dataUri: string; filename: string }) => {
        const postFile = dataURLtoFile(file.dataUri, file.filename) as File;
        formData.append(file.filename, postFile);
      });
      return;
    }
    formData.append(`${key}`, body[key as keyof typeof body]);
  });

  const res = await $axios
    .post(url, formData)
    .then((res) => res.data)
    .catch(() => console.log('ERROR'));

  if (res && res.status === 200 && onSuccess) {
    onSuccess();
  }
  if ((!res || res.status !== 200) && onError) {
    onError();
  }
};

const usePostFetcher = (
  url: string,
  body: { [key: string]: any },
  onSuccess?: () => void,
  onError?: () => void,
) => {
  const { isLoading, isError, error, mutate } = useMutation(
    async () => {
      const { data } = await $axios.post(url, body);
      return data;
    },
    { onSuccess, onError },
  );
  return { isLoading, isError, error, mutate };
};

export { useFetcher, postFetcher, usePostFetcher };
