import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { MdNavigateNext } from 'react-icons/md';

import { initialMain, MainType } from '@comp/main/MainTypes';

import { useFetcher } from '@hook/useFetcher';

import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';
import { ContentGrid, PageTitleWrap } from '@src/layout';

export default function MainNotice() {
  const [noticeData, setNoticeData] = useState<MainType['notices']>(initialMain.notices);
  const { data } = useFetcher('admin/main');

  useEffect(() => {
    if (data) {
      setNoticeData(data.data.notices);
    }
  }, [data]);

  return (
    <Wrap>
      <NoticeHeader>
        <h1>공지사항</h1>
        <Link to='/notice'>
          <MdNavigateNext size={25} color='#C3C3C3' />
        </Link>
      </NoticeHeader>
      <NoticeGrid>
        {noticeData.map((notice) => {
          return (
            <Link to={`/notice/${notice.id}`} key={notice.id}>
              <Notice>
                {notice.category === 'NORMAL' ? '[일반] ' : '[시스템] '}
                {notice.title}
              </Notice>
            </Link>
          );
        })}
      </NoticeGrid>
    </Wrap>
  );
}

const Wrap = styled(PageTitleWrap)`
  flex-grow: 1;
  max-width: 25%;
  border: 1px solid ${theme.border.gray};
  h1 {
    padding: ${pixelToRem(21)} ${pixelToRem(27)} ${pixelToRem(17)};
    border: none;
  }
`;

const NoticeHeader = styled.div`
  padding-right: ${pixelToRem(21)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${theme.border.gray};

  & > h1 {
    font-weight: 500;
  }
  & > a {
    padding-top: ${pixelToRem(5)};
  }
`;

const NoticeGrid = styled(ContentGrid)`
  min-height: auto;
  padding-top: ${pixelToRem(24)};
  padding-bottom: ${pixelToRem(18)};
  gap: ${pixelToRem(24)};
  border: none;
  background-color: ${theme.background.white};
  font-size: ${pixelToRem(18)};
  overflow-y: hidden;
`;

const Notice = styled.div`
  width: 100%;
  color: ${theme.color.black};
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
`;
