/* eslint-disable no-param-reassign */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import validateToken, { parseToken } from '@util/tokenUtil';

export type RetSuccessData<T> = {
  status: AxiosResponse['status'];
  msg: string;
  data: T;
};

export type RetErrorData = {
  status: AxiosResponse['status'];
  msg: string;
};

const requireApiList = [
  { method: 'post', url: 'admin/user/block' },
  { method: 'get', url: 'admin/user' },
  { method: 'get', url: 'admin/user/:userId' },
  { method: 'post', url: 'admin/user/block' },
  { method: 'post', url: 'admin/reply' },
  { method: 'post', url: 'admin/qna/delete/:qnaId' },
  { method: 'get', url: 'admin/qna/:qnaId' },
  { method: 'get', url: 'admin/qna' },
  { method: 'post', url: 'admin/refund' },
  { method: 'post', url: 'admin/crawler/block' },
  { method: 'post', url: 'admin/crawler/active' },
  { method: 'post', url: 'admin/crawler' },
  { method: 'get', url: 'admin/crawler/full' },
  { method: 'post', url: 'admin/crawler/update' },
  { method: 'post', url: 'admin/account/login' },
  { method: 'post', url: 'admin/account/check' },
  { method: 'post', url: 'admin/account/find/username' },
  { method: 'post', url: 'admin/account/change/password' },
  { method: 'post', url: 'admin/notice/delete' },
  { method: 'post', url: 'admin/notice/edit' },
  { method: 'post', url: 'admin/notice' },
  { method: 'get', url: 'admin/notice/:id' },
  { method: 'get', url: 'admin/notice' },
  { method: 'get', url: 'admin/action/total' },
  { method: 'post', url: 'admin/action/charge' },
  { method: 'post', url: 'admin/action/refund' },
  { method: 'post', url: 'admin/action/reject' },
  { method: 'get', url: 'admin/main' },
  { method: 'get', url: 'admin/main/analyze' },
  { method: 'get', url: 'admin/search' },
  { method: 'get', url: 'admin/search/:id' },
  { method: 'get', url: 'admin/charge/exist' },
  { method: 'get', url: 'admin/charge/history' },
  { method: 'get', url: 'admin/charge' },
  { method: 'get', url: 'admin/report/list' },
];

const isProduction = process.env.NODE_ENV === 'production';
const normalPrefix = 'http://localhost';
const PREFIX = isProduction ? 'https://admin.metu.ai' : `${normalPrefix}:4000`;
export const baseUrl = isProduction ? 'https://api.metu.ai/' : `${normalPrefix}:3000`;

const $axios = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  maxContentLength: 10 * 1024 * 1024,
  maxBodyLength: 10 * 1024 * 1024,
});

$axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
  config.baseURL = baseUrl;
  const { url, method } = config;
  if (
    requireApiList.findIndex(
      (api) => (url as string).indexOf(api.url) > -1 && api.method === method,
    ) > -1
  ) {
    if (url === 'admin/account/login') {
      return config;
    }
    if (validateToken()) {
      const token = localStorage.getItem('adminToken') as string;
      const { exp } = parseToken(token);
      if (exp * 1000 - Date.now() > 0) {
        config.headers!.token = token;
        return config;
      }
      localStorage.removeItem('adminToken');
      return Promise.reject(new Error('You need to Login'));
    }
    localStorage.removeItem('adminToken');

    return Promise.reject(new Error('You need to Login'));
  }
  return config;
});

$axios.interceptors.response.use(
  (res) => Promise.resolve(res),
  (err) => {
    const { message } = err;
    if (message === 'You need to Login') {
      if (window && window.location) {
        window.location.href = `${PREFIX}/`;
      }
    }
    return err.response;
  },
);

export default $axios;

export const fetcher = async (config: AxiosRequestConfig) => {
  const { data } = await $axios(config);
  return data;
};
