import React, { memo } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

type PageTitleprops = {
  id: number;
  title: string;
  subText?: string;
  path: string | string[];
};
export type listTypeprops = PageTitleprops[];

export const PageTitle: listTypeprops = [
  {
    id: 0,
    title: '미투 현황',
    subText: '(최근 1주 기준)',
    path: '/main',
  },
  {
    id: 1,
    title: '검색 관리',
    path: '/search',
  },
  {
    id: 2,
    title: '신고 관리',
    path: '/report',
  },
  {
    id: 3,
    title: '이용 관리',
    path: ['/action', '/payment', '/charge', '/refund', '/usage'],
  },
  {
    id: 4,
    title: '유저 관리',
    path: '/user',
  },
  {
    id: 5,
    title: '문의 관리',
    path: '/qna',
  },
  {
    id: 6,
    title: '공지사항 관리',
    path: '/notice',
  },
];
function PageTit({ children }: { children?: React.ReactNode }) {
  const { pathname } = useLocation();
  const pageTitle: PageTitleprops | undefined = PageTitle.find(
    (a) => a.path.indexOf(pathname) > -1,
  );
  const title = children || pageTitle?.title;
  // eslint-disable-next-line react/react-in-jsx-scope
  return <TitleWrap>{title || ''}</TitleWrap>;
}

const TitleWrap = styled.h1`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export default memo(PageTit);
