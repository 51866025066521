import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AiOutlineSearch, AiOutlineMessage } from 'react-icons/ai';
import { BiCoinStack } from 'react-icons/bi';
import { FiAlertOctagon } from 'react-icons/fi';
import { theme } from '@util/theme';
import { today } from '@util/commonUtil';
import { pixelToRem } from '@util/commonStyles';
import { RootState } from '@reducer/index';
import activeTabSlice from '@reducer/common/activeTab';

interface MainWeekItemProps {
  title: string;
  items: { [key: string]: number };
}

interface TitleObjProps {
  [key: string]: [React.ReactNode, string];
}

interface ContentObjProps {
  [key: string]: [string, string, string];
}

function ItemContents({ title, items }: Partial<MainWeekItemProps>) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { startDate: startdate, endDate: enddate } = useSelector(
    (state: RootState) => state.periodFilter,
  );

  const periodString = `startdate=${today
    .add(-6, 'day')
    .format('YYYY-MM-DD')}&enddate=${today.format('YYYY-MM-DD')}`;

  const contentObj: ContentObjProps = {
    request: ['검색 요청', '건', `/search?${periodString}`],
    requested: ['총 검색 데이터', '건', ''],
    report: ['신고 요청', '건', '/report'],
    noReport: ['신고 완료', '건', '/report'],
    charge: ['총 충전 금액', '원', '/action'],
    usage: ['총 소진 금액', '원', '/action'],
    refund: ['총 환불 금액', '원', '/action'],
    payment: ['총 결제 금액', '원', '/action'],
    reply: ['답변', '건', `/qna?${periodString}&isreply=true`],
    noReply: ['미답변', '건', `/qna?${periodString}&isreply=false`],
  };

  const keys = {
    qna: ['reply', 'noReply'],
    report: ['report', 'noReport'],
    price: ['payment', 'charge', 'usage', 'refund'],
    search: ['request', 'requested'],
  };

  const moveToPage = (key: keyof typeof contentObj) => {
    if (key === 'report') {
      dispatch(activeTabSlice.actions.setActiveTab('신고요청'));
      navigate(contentObj[key][2], { state: { name: '', startdate, enddate, isreported: '0' } });
      return;
    }
    if (key === 'noReport') {
      dispatch(activeTabSlice.actions.setActiveTab('신고완료'));
      navigate(contentObj[key][2], { state: { name: '', startdate, enddate, isreported: '1' } });
      return;
    }
    if (key === 'payment') {
      dispatch(activeTabSlice.actions.setActiveTab('결제'));
    }
    if (key === 'charge') {
      dispatch(activeTabSlice.actions.setActiveTab('충전'));
    }
    if (key === 'usage') {
      dispatch(activeTabSlice.actions.setActiveTab('소진'));
    }
    if (key === 'refund') {
      dispatch(activeTabSlice.actions.setActiveTab('환불'));
    }
    navigate(contentObj[key][2], { state: { type: key, name: '' } });
  };

  return (
    <ItemContentWrap>
      {keys[title as keyof typeof keys].map((href, index) => {
        const key = keys[title as keyof typeof keys][index];
        return (
          <ItemContent key={key}>
            {href === 'requested' ? (
              <>
                <ContentTitle>{contentObj[key][0]}</ContentTitle>
                <ContentAmount>
                  {items![key].toLocaleString('ko')}{' '}
                  <ContentAmountUnit>{contentObj[key][1]}</ContentAmountUnit>
                </ContentAmount>
              </>
            ) : (
              <ContentLink onClick={() => moveToPage(key)}>
                <ContentTitle>{contentObj[key][0]}</ContentTitle>
                <ContentAmount>
                  {items![key].toLocaleString('ko')}{' '}
                  <ContentAmountUnit>{contentObj[key][1]}</ContentAmountUnit>
                </ContentAmount>
              </ContentLink>
            )}
          </ItemContent>
        );
      })}
    </ItemContentWrap>
  );
}

export default function MainWeekItem({ title, items }: MainWeekItemProps) {
  const titleObj: TitleObjProps = {
    search: [<AiOutlineSearch key='search' size={25} />, '검색'],
    report: [<FiAlertOctagon key='report' size={25} />, '신고'],
    price: [<BiCoinStack key='action' size={25} />, '이용'],
    qna: [<AiOutlineMessage key='qna' size={25} />, '문의'],
  };

  return (
    <ItemWrap>
      <ItemTitleWrap>
        {titleObj[title][0]}&nbsp;&nbsp;{titleObj[title][1]}
      </ItemTitleWrap>
      <ItemContents title={title} items={items} />
    </ItemWrap>
  );
}

const ItemWrap = styled.div`
  width: 25%;
  padding: ${pixelToRem(36)} ${pixelToRem(54)};
  display: flex;
  flex-direction: column;
  gap: ${pixelToRem(20)};
  border-right: 1px solid ${theme.border.gray};

  &:last-child {
    border-right: none;
  }
`;

const ItemTitleWrap = styled.div`
  display: flex;
  align-items: center;
  font-size: ${pixelToRem(20)};
  font-weight: 500;
`;

const ItemContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pixelToRem(19)};
`;

const ItemContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContentLink = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: ${theme.color.black};
  text-decoration: none;
  cursor: pointer;

  & > span {
    border-bottom: 1px solid ${theme.border.white};
  }

  &:hover > span {
    border-bottom: 1px solid ${theme.border.gray};
  }
`;

const ContentTitle = styled.span`
  color: ${theme.color.gray808285};
`;

const ContentAmount = styled.span`
  font-weight: 700;
`;

const ContentAmountUnit = styled.span`
  font-weight: 500;
`;
