import styled from 'styled-components';

import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';

import { TableInfoProps } from '@comp/common/table/TableHeadList';

export type TableStyleProps = {
  tableInfo: TableInfoProps;
};
export const TableWrap = styled.div`
  border: 1px solid ${theme.border.gray};
  border-right: none;
  text-align: center;
`;
export const RowHead = styled.div<TableStyleProps>`
  display: grid;
  grid-template-columns: ${({ tableInfo }) => {
    return tableInfo.colgroup.map((r) => `minmax(0, ${r}fr)`).join(' ');
  }};
  border-bottom: 1px solid ${theme.border.gray};
  background-color: ${theme.background.semiWhite};

  div {
    min-width: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${pixelToRem(16)};
    color: ${theme.color.black};
    border-right: 1px solid ${theme.border.gray};
    font-size: ${pixelToRem(15)};
    font-weight: 600;
    span,
    a {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;
export const RowBody = styled(RowHead)`
  background-color: ${theme.background.white};
  border-bottom: 1px solid ${theme.border.gray};
  div {
    font-weight: 400;
    padding: 0 ${pixelToRem(16)};
    height: ${pixelToRem(60)};
    line-height: 1;
  }
  div,
  span {
    color: ${theme.color.gray707070};
  }
  &:last-child {
    border-bottom: none;
  }
  &.empty {
    padding: ${pixelToRem(20)} 0;
    text-align: center;
    grid-template-columns: 1fr;
    border-right: 1px solid ${theme.border.gray};
  }
  span.mainColor {
    color: ${theme.color.blue};
  }
`;
