export type TableInfoProps = {
  [property: string]: string[];
};
export type TablePathProps = {
  [property: string]: TableInfoProps;
};

export const TableInfoList: TablePathProps = {
  search: {
    thead: ['No', '사용자', '검색요청일', '검색한 HASH 값', '검색한 IP 주소'],
    colgroup: ['8', '11', '11', '50', '20'],
  },
  'search/detail': {
    thead: ['No', '이미지', '사용자', '사이트 명', '사이트 주소', '사이트 리전', '매칭률'],
    colgroup: ['8', '10', '10', '12', '35', '17', '8'],
  },
  report: {
    thead: [
      'No',
      '이미지',
      '사용자',
      '신고요청일',
      '신고완료일',
      '신고여부',
      '사이트명',
      '사이트 주소',
      '매칭률',
      '사이트 리전',
      '비고',
    ],
    colgroup: ['5', '6', '6', '10', '10', '5', '5', '36', '6', '6', '5'],
  },
  action: {
    thead: ['No', '사용자', '상세조건', '이용금액', '이용일자', '비고'],
    colgroup: ['7', '12', '12', '12', '12', '45'],
  },
  payment: {
    thead: [
      'No',
      '사용자',
      '결제 고유번호',
      '카드사',
      '카드번호',
      '결제금액',
      '결제일',
      '결제상태',
      '수동충전',
      '비고',
    ],
    colgroup: ['6', '8', '12', '8', '12', '10', '10', '10', '12', '12'],
  },
  charge: {
    thead: ['No', '사용자', '상세조건', '충전요청금액', '충전요청일자'],
    colgroup: ['5', '20', '25', '25', '25'],
  },
  usage: {
    thead: ['No', '사용자', '상세조건', '소진금액', '소진일자'],
    colgroup: ['5', '20', '25', '25', '25'],
  },
  refund: {
    thead: [
      'No',
      '사용자',
      '상세조건',
      '환불요청금액',
      '환불요청일자',
      '환불상태',
      '환불처리',
      '비고',
    ],
    colgroup: ['7', '15', '15', '15', '15', '11', '15', '7'],
  },
  user: {
    thead: [
      'No',
      '이름',
      '전화번호',
      '생년월일',
      '최초인증여부',
      '신분증등록여부',
      '차단여부',
      '가입일',
      '관리',
    ],
    colgroup: ['5', '12', '12', '12', '12', '12', '12', '12', '11'],
  },
  qna: {
    thead: ['No', '사용자', '등록일', '제목', '첨부파일', '답변여부', '관리'],
    colgroup: ['5', '11', '11', '40', '11', '11', '11'],
  },
  notice: {
    thead: ['No', '구분', '등록일', '제목', '첨부파일', '관리'],
    colgroup: ['5', '10', '10', '45', '10', '20'],
  },
};
