import dayjs from 'dayjs';
import { PostFileType } from './initialData';

export function getFileList(files: PostFileType[]) {
  const fileList: { dataUri: string; filename: string }[] = [];
  for (const file of files) {
    if (file instanceof File) {
      const fileReader = new FileReader();
      const filename = file.name;
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        const dataUri = fileReader.result?.toString() as string;
        fileList.push({ dataUri, filename });
      };
    } else {
      fileList.push(file);
    }
  }
  return fileList;
}

export function dataURLtoFile(dataurl: string, fileName: string): File | null {
  if (dataurl && dataurl.length > 5) {
    const arr = dataurl.split(',') as string[] | any[];
    if (arr && typeof arr === 'object' && arr.length > 0) {
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = window.atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], fileName, { type: mime });
    }
  }
  return null;
}

export const onClickFileButton = (Bucket: string, Key: string, filename: string) => {
  const url = `https://${Bucket}.s3.ap-northeast-2.amazonaws.com/${Key}`;
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const getCurrentUrl = (str: string): string => {
  return str.substring(1);
};

export function convertPrettyKST(
  time: string | number | Date,
  simple?: boolean,
  hmsOnly?: boolean,
): string {
  try {
    const dateObj = new Date(time);
    const date = `0${dateObj.getDate()}`.slice(-2);
    const month = `0${dateObj.getMonth() + 1}`.slice(-2);
    const year = dateObj.getFullYear();
    const hour = `0${dateObj.getHours()}`.slice(-2);
    const minute = `0${dateObj.getMinutes()}`.slice(-2);
    const second = `0${dateObj.getSeconds()}`.slice(-2);
    if (simple) {
      if (hmsOnly) return `${hour}:${minute}:${second}`;
      return `${year}${month}${date}_${hour}${minute}${second}`;
    }
    return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
  } catch (err) {
    return '';
  }
}

export const today = dayjs(new Date());