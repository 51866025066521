import React, { memo } from 'react';
import styled from 'styled-components';

import { useLocation } from 'react-router-dom';

import { pixelToRem } from '@util/commonStyles';

import { ReportType } from '@comp/report/ReportTypes';
import { TableInfoList } from '@comp/common/table/TableHeadList';
import { RowBody } from '@comp/common/table/styles';

type ReportTableDataProps = Pick<ReportType, 'reports'>;
type ReportTotalProps = ReportTableDataProps & {
  totalCount: number;
  page: number;
};

function ReportTableData({ reports, totalCount, page }: ReportTotalProps) {
  const location = useLocation();

  const currentUrl: string = location.pathname.split('/')[1];
  const TableInfo = TableInfoList[currentUrl];

  return (
    <>
      {reports.length > 0 ? (
        reports.map((userData, index) => (
          <RowBody tableInfo={TableInfo} key={index}>
            <div>
              <span>{totalCount - index - 10 * (page - 1)}</span>
            </div>
            <div>
              <Image src={userData.imageUrl} />
            </div>
            <div>{userData.name}</div>
            {userData.reportedAt ? <div>{userData.reportedAt.split(' ')[0]}</div> : <div></div>}
            {userData.completedAt ? <div>{userData.completedAt.split(' ')[0]}</div> : <div></div>}
            {userData.isReported ? <div>신고완료</div> : <div>신고요청</div>}
            <div>{userData.target}</div>
            <div>
              <span>{userData.detailUrl}</span>
            </div>
            <div>
              <span>{String(userData.percentage).split('.')[0]}</span>
            </div>
            <div>
              <span>OV</span>
            </div>
            <div>
              <span>-</span>
            </div>
          </RowBody>
        ))
      ) : (
        <RowBody tableInfo={TableInfo} className='empty'>
          내역이 없습니다.
        </RowBody>
      )}
    </>
  );
}

const Image = styled.img`
  width: ${pixelToRem(43)};
  height: ${pixelToRem(43)};
`;
export default memo(ReportTableData);
