/* eslint-disable operator-linebreak */
import React from 'react';
import styled from 'styled-components';

import { withDetail, BasePropsType } from '@comp/common/wrapper/DetailWrapper';
import PageTitle from '@comp/common/PageTitle';
import FooterButton from '@comp/common/FooterButton';
import { FileButton } from '@comp/common/StyledButton';
import Row from '@comp/common/Row';
import Box from '@comp/common/Box';
import { initialQnaDetail, QnaDetailType } from '@comp/qna/QnaTypes';

import { PageTitleWrap } from '@src/layout';

import { theme } from '@util/theme';
import { onClickFileButton } from '@util/commonUtil';
import { pixelToRem } from '@util/commonStyles';

function QnaDetailBase(props: BasePropsType<QnaDetailType>) {
  const { data, name, onClickList, onClickEdit } = props;
  const qnaData = data ? data.qna : initialQnaDetail.qna;

  return (
    <PageTitleWrap>
      <PageTitle>문의 상세 내역</PageTitle>
      <DetailHeader>
        <Row title='사용자'>
          <Box width={260} height={40}>
            {name}
          </Box>
        </Row>
        <Row title='등록일'>
          <Box width={260} height={40}>
            {qnaData.createdAt}
          </Box>
        </Row>
        <Row title='제목'>
          <Box height={40}>
            <DetailTitle>{qnaData.title}</DetailTitle>
          </Box>
        </Row>
      </DetailHeader>
      <DetailBody>
        <Row title='내용'>
          <Box height={435}>
            <DetailContent>{qnaData.content}</DetailContent>
          </Box>
        </Row>
        <Row title='첨부파일'>
          {qnaData.postFiles &&
            qnaData.postFiles.map((file) => {
              return (
                <FileButton
                  key={file.id}
                  type='button'
                  onClick={() => onClickFileButton(file.Bucket!, file.Key!, file.name)}
                >
                  {file.name}
                </FileButton>
              );
            })}
        </Row>
      </DetailBody>
      <FooterButton
        list={{ type: 'button', text: '목록', onClick: onClickList }}
        submit={{ type: 'submit', text: '답변', onClick: onClickEdit }}
      />
    </PageTitleWrap>
  );
}

export default function QnaDetail() {
  return withDetail<QnaDetailType>(QnaDetailBase);
}

const DetailHeader = styled.div`
  width: 100%;
  padding: ${pixelToRem(15)} 0 ${pixelToRem(9)} 0;
  display: flex;
  flex-direction: column;
  gap: ${pixelToRem(8)};
`;

const DetailTitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${theme.color.blue};
`;

const DetailBody = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${theme.border.gray};

  & > div {
    margin-bottom: ${pixelToRem(20)};
  }
  & > div:nth-child(2) {
    margin-bottom: ${pixelToRem(29)};
  }
`;

const DetailContent = styled.p`
  align-self: flex-start;
  padding-top: ${pixelToRem(13)};
  white-space: pre-wrap;
  line-height: ${pixelToRem(20)};
`;
