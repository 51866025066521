import React, { useCallback, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import useModal from '@hook/useModal';

import $axios from '@config/axios';

import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';

import Modal from '@comp/common/modal/Modal';
import Button from '@comp/common/Button';
import { TableInfoList } from '@comp/common/table/TableHeadList';
import { RowBody } from '@comp/common/table/styles';

import { ActionType } from '@comp/action/ActionTypes';

import { RootState } from '@reducer/index';
import actionListSlice from '@reducer/action/actionDataList';
import actionPaymentSlice from '@reducer/action/actionPayment';

// NOTE : totalPrices를 뺀 histories와 type만 쓰는데 Omit을 쓰지 않아도 됩니당
// NOTE : Omit, Partial, Pick 등의 유틸리티 타입을 잘 섞어 쓰는건 칭찬하지만
// NOTE : 해도 되지 않아도 되는 곳은 안해도 됩니다. 좀더 능동적이면 좋겠어용

type ActionTableDataProps = Omit<ActionType, 'totalPrices'>;
type totalCountPropsType = ActionTableDataProps & {
  totalCount: number;
  page: number;
};
function ActionTableData({ type, page, histories, totalCount }: totalCountPropsType) {
  const dispatch = useDispatch();
  const { isShow, onShow, onNoShow } = useModal();
  const TableInfo = TableInfoList[type as string];

  const userId = useSelector((state: RootState) => state.actionPayment.userId);
  const name = useSelector((state: RootState) => state.actionPayment.name);
  const price = useSelector((state: RootState) => state.actionPayment.price);
  const receiptId = useSelector((state: RootState) => state.actionPayment.receiptId);

  const chargeOnClick = (userId: number, name: string, price: number, receiptId: string) => {
    onShow();
    dispatch(
      actionPaymentSlice.actions.onSelect({
        userId,
        name,
        price,
        receiptId,
      }),
    );
  };

  const chargePost = async (id: number, price: number, receiptId: string) => {
    await $axios
      .post('admin/action/charge', {
        userId: id,
        price,
        receiptId,
      })
      .then((res) => {
        if (res && res.data && res.data.status === 200) {
          onNoShow();
        }
      });
  };

  const refundPost = useCallback(
    async (id: number, price: number, reason: string, idx: number) => {
      await $axios
        .post('admin/action/refund', {
          id,
          price,
          reason,
        })
        .then((res) => {
          if (res && res.data && res.data.status === 200) {
            dispatch(actionListSlice.actions.setIsRefund({ idx, isRefund: 1 }));
          }
        });
    },
    [dispatch, actionListSlice],
  );

  const rejectPost = useCallback(
    async (id: number, idx: number) => {
      await $axios
        .post('admin/action/reject', {
          id,
        })
        .then((res) => {
          if (res && res.data && res.data.status === 200) {
            dispatch(actionListSlice.actions.setIsRefund({ idx, isRefund: -1 }));
          }
        });
    },
    [dispatch, actionListSlice],
  );
  return (
    <>
      <Modal
        isShow={isShow}
        h1Text={`${name} 고객님 ${price}원을 수동으로 충전하시겠습니까?`}
        firstBtn={{
          isDisplay: true,
          content: '확인',
          onClick: () => {
            chargePost(userId, price, receiptId);
          },
        }}
        secondBtn={{ isDisplay: true, content: '취소', onClick: onNoShow }}
      />
      {histories.length > 0 ? (
        histories.map((userData, index) => (
          <RowBody tableInfo={TableInfo} key={index}>
            <div>
              <span>{totalCount - index - 10 * (page - 1)}</span>
            </div>
            <div>
              <span>{userData.name}</span>
            </div>
            {type === 'action' && (
              <>
                <div>
                  <span>
                    {userData.type === 'PAYMENT' && '결제'}
                    {userData.type === 'CHARGE' && '충전'}
                    {userData.type === 'USAGE' && '소진'}
                    {userData.type === 'REFUND' && '환불'}
                  </span>
                </div>
                <div>
                  <span>{userData.price.toLocaleString()}원</span>
                </div>
                <div>
                  <span>{userData.createdAt.split(' ')[0]}</span>
                </div>
                <div>-</div>
              </>
            )}
            {type === 'payment' && (
              <>
                <div>
                  <span>{userData.receiptId}</span>
                </div>
                <div>
                  <span>{userData.cardName}</span>
                </div>
                <div>
                  <span>{userData.cardNo}</span>
                </div>
                <div>
                  <span>{userData.price.toLocaleString()}원</span>
                </div>
                <div>
                  <span>{userData.createdAt.split(' ')[0]}</span>
                </div>
                <div>
                  <span
                    style={{ color: [0, -2].includes(userData.status) ? theme.color.blue : '' }}
                  >
                    결제
                    {userData.status === 0
                      ? '대기'
                      : userData.status === 20
                      ? '취소'
                      : userData.status === 1
                      ? '완료'
                      : '거부'}
                  </span>
                </div>
                <div>
                  <span>
                    <Button
                      padding={`${pixelToRem(11)} 0`}
                      borderColor={`1px solid ${theme.border.gray}`}
                      fontColor={`${theme.border.black}`}
                      fontSize={14}
                      isHover={true}
                      width={85}
                      hoverBgColor={theme.background.mainColor}
                      hoverColor={theme.color.white}
                      hoverBorder={`1px solid ${theme.border.mainColor}`}
                      isDisabled={userData.status !== 1}
                      onClick={() => {
                        chargeOnClick(
                          userData.userId,
                          userData.name,
                          userData.price,
                          userData.receiptId,
                        );
                      }}
                    >
                      충전
                    </Button>
                  </span>
                </div>
                <div>
                  <span>{userData.status === -2 && '서버오류'}</span>
                </div>
              </>
            )}
            {type === 'charge' && (
              <>
                <div>
                  <span>충전</span>
                </div>
                <div>
                  <span>{userData.price.toLocaleString()}원</span>
                </div>
                <div>
                  <span>{userData.createdAt.split(' ')[0]}</span>
                </div>
              </>
            )}
            {type === 'usage' && (
              <>
                <div>
                  <span>소진</span>
                </div>
                <div>
                  <span>{userData.price.toLocaleString()}원</span>
                </div>
                <div>
                  <span>{userData.createdAt.split(' ')[0]}</span>
                </div>
              </>
            )}
            {type === 'refund' && (
              <>
                <div>
                  <span>환불</span>
                </div>
                <div>
                  <span>{userData.price.toLocaleString()}원</span>
                </div>
                <div>
                  <span>{userData.createdAt.split(' ')[0]}</span>
                </div>
                <div>
                  <span className={userData.isRefund === 0 ? 'mainColor' : ''}>
                    환불
                    {userData.isRefund === 0 ? '요청' : userData.isRefund === 1 ? '완료' : '거부'}
                  </span>
                </div>
                <div>
                  <Button
                    margin={`0 ${pixelToRem(7)} 0 0`}
                    fontColor={`${theme.color.black}`}
                    padding={`${pixelToRem(11)} 0`}
                    fontSize={14}
                    width={85}
                    borderColor={`1px solid ${theme.border.gray}`}
                    isHover={true}
                    hoverBgColor={theme.background.mainColor}
                    hoverColor={theme.color.white}
                    hoverBorder={`1px solid ${theme.border.mainColor}`}
                    isDisabled={![0].includes(userData.isRefund)}
                    onClick={() => refundPost(userData.id, userData.price, userData.reason, index)}
                  >
                    환불
                  </Button>
                  <Button
                    margin={`0 ${pixelToRem(7)} 0 0`}
                    fontColor={`${theme.color.black}`}
                    padding={`${pixelToRem(11)} 0`}
                    fontSize={14}
                    width={85}
                    borderColor={`1px solid ${theme.border.gray}`}
                    isHover={true}
                    hoverBgColor={theme.background.mainColor}
                    hoverColor={theme.color.white}
                    hoverBorder={`1px solid ${theme.border.mainColor}`}
                    isDisabled={![0].includes(userData.isRefund)}
                    onClick={() => rejectPost(userData.id, index)}
                  >
                    거부
                  </Button>
                </div>
                <div>
                  <span>-</span>
                </div>
              </>
            )}
          </RowBody>
        ))
      ) : (
        <RowBody tableInfo={TableInfo} className='empty'>
          내역이 없습니다.
        </RowBody>
      )}
    </>
  );
}

export default memo(ActionTableData);
