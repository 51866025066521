import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { withList, BasePropsType } from '@comp/common/wrapper/ListWrapper';
import PageTitle from '@comp/common/PageTitle';
import FilterItem from '@comp/common/FilterItem';
import FilterButton from '@comp/common/FilterButton';
import Table from '@comp/common/table/Table';
import Pagination from '@comp/common/Pagination';
import QnaTableData from '@comp/qna/QnaTableData';
import { QnaListType, initialQnaList } from '@comp/qna/QnaTypes';

import { RootState } from '@reducer/store';

import { ListLayout, FilterBox, InnerList, PageTitleWrap, SectionTitle } from '@src/layout';

import { pixelToRem } from '@util/commonStyles';
import { todayString } from '@util/string';

const replyQuery = {
  전체: '',
  답변: 'true',
  미답변: 'false',
};

function Base(props: BasePropsType<QnaListType>) {
  const { state, data, page, setQuery, onClickSearch, onClickReset, onClickPage } = props;

  const name = useSelector((state: RootState) => state.inputData.inputData);
  const { startDate, endDate } = useSelector((state: RootState) => state.periodFilter);
  const tabName = useSelector((state: RootState) => state.activeTab.activeTab);

  const query = {
    name: state && state.query ? state.query.name : name.trim(),
    startdate: state && state.query ? state.query.startdate : startDate,
    enddate: state && state.query ? state.query.enddate : endDate || todayString,
    isreply:
      state && state.query
        ? state.query.isreply || ''
        : replyQuery[tabName as keyof typeof replyQuery],
    page: state && state.query ? state.query.page : `${page}`,
  };

  useEffect(() => {
    setQuery(query);
  }, [name, startDate, endDate, tabName, page]);

  const qnaList = data || initialQnaList;

  return (
    <>
      <PageTitleWrap>
        <PageTitle />
        <FilterBox>
          <FilterItem theadText='사용자' hasInput margin={`0 0 ${pixelToRem(10)} 0`} />
          <FilterItem theadText='조회기간' hasPeriod margin={`0 0 ${pixelToRem(10)} 0`} />
          <FilterItem theadText='검색조건' hasTab />
        </FilterBox>
        <FilterButton onClickSearch={onClickSearch} onClickReset={onClickReset} />
      </PageTitleWrap>
      <ListLayout>
        <SectionTitle>
          전체 문의 목록 <span>({qnaList.totalCount.toLocaleString('ko')}건)</span>
        </SectionTitle>
        <InnerList>
          <Table>
            <QnaTableData qnas={qnaList.qnas} query={query} />
          </Table>
          <Pagination totalCount={qnaList.totalCount} page={page} setPage={onClickPage} />
        </InnerList>
      </ListLayout>
    </>
  );
}

export default function QnaList() {
  return withList<QnaListType>(Base);
}
