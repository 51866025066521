import styled from 'styled-components';

import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';

// common layout
export const LayoutGrid = styled.div`
  display: flex;
  flex: 1;
`;
export const ContentGrid = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding: ${pixelToRem(25)} ${pixelToRem(25)} 0 ${pixelToRem(25)};
  background-color: ${theme.background.lightBlue};
  min-height: 100vh;
`;
export const InnerFlex = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const SectionTitle = styled.h1`
  font-size: ${pixelToRem(19)};
  color: ${theme.color.lightBlack};
  padding: ${pixelToRem(20)} ${pixelToRem(27)};
  border-bottom: 1px solid ${theme.border.gray};
`;

export const ListLayout = styled.div`
  background: ${theme.background.white};
  border: 1px solid ${theme.border.gray};
`;
export const InnerList = styled.div`
  padding: ${pixelToRem(25)};
`;
export const PageTitleWrap = styled.div`
  background: #fff;
  border: 1px solid ${theme.border.gray};
  margin-bottom: ${pixelToRem(20)};
  h1 {
    font-size: ${pixelToRem(20)};
    font-weight: 600;
    padding: ${pixelToRem(20)} ${pixelToRem(27)};
    border-bottom: 1px solid ${theme.border.gray};
  }
`;
export const FilterBox = styled.div`
  padding: ${pixelToRem(15)} ${pixelToRem(27)};
  border-bottom: 1px solid ${theme.border.gray};
`;
export const TabWrap = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 140px);
`;

// header
export const HeaderWrap = styled.div`
  padding: 0 ${pixelToRem(30)};
  background-color: ${theme.background.mainColor};
  display: flex;
  height: ${pixelToRem(72)};
  line-height: ${pixelToRem(72)};
`;
export const LogoHead = styled.div`
  flex: none;
  img {
    width: 164px;
    height: 29px;
    display: inline-block;
    vertical-align: middle;
  }
`;
export const AdminButton = styled.ul`
  margin-left: auto;
  li {
    display: inline-block;
    vertical-align: middle;
    color: ${theme.color.white};
    font-size: 16px;
    margin-left: 20px;
  }
  li button {
    border: none;
    background: none;
    color: ${theme.color.white};
    font-size: 16px;
  }
`;
// footer
export const FooterWrap = styled.div`
  padding: 20px;
  text-align: center;
  width: 100%;
  margin-top: auto;
`;
export const FooterInfo = styled.ul`
  width: 75%;
  margin: 0 auto;
  li {
    display: inline-block;
    vertical-align: top;
    color: ${theme.color.gray808285};
    font-size: ${pixelToRem(13)};
  }
  li:after {
    content: '|';
    display: inline-block;
    vertical-align: top;
    margin: 0 ${pixelToRem(8)};
    font-size: ${pixelToRem(13)};
    color: ${theme.color.gray808285};
  }
  li:last-child:after {
    content: none;
  }
`;
