import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFetcher, postFetcher } from '@hook/useFetcher';
import { NoticeDetailType } from '@comp/notice/NoticeTypes';
import { QnaDetailType } from '@comp/qna/QnaTypes';

type DetailType = NoticeDetailType | QnaDetailType;

export type BasePropsType<T extends DetailType> = {
  data: T | undefined;
  id: string;
  name: string;
  type: string;
  onClickList: () => void;
  onClickToCreate: ({ ...args }) => void;
  onClickToModify: ({ ...args }) => void;
  onClickCancel: () => void;
};

export function withEdit<T extends DetailType>(
  Component: (props: BasePropsType<T>) => JSX.Element,
) {
  const navigate = useNavigate();
  const { pathname, state }: { pathname: string; state: any } = useLocation();

  const getType = useCallback(() => {
    if (pathname.split('/')[1] === 'notice') {
      if (state && state.id) {
        return 'notice/modify';
      }
      return 'notice/create';
    }
    return 'qna/reply';
  }, [pathname, state]);

  const list = pathname.split('/')[1];
  const type = getType();
  const id = state && state.id ? state.id : '-1';
  const name = state && state.name ? state.name : '';
  const query = state ? state.query : {};

  const onClickList = useCallback(() => {
    navigate(`/${list === 'notice' ? list : 'qna'}`, { state: { query } });
  }, [pathname]);

  const onClickToCreate = useCallback(
    ({ ...args }) => {
      const apiUrl = list === 'notice' ? 'admin/notice' : 'admin/reply';
      const redirectUrl = list === 'notice' ? '/notice' : '/qna';
      postFetcher(
        apiUrl,
        {
          ...args,
        },
        () => navigate(redirectUrl, { state: { query } }),
      );
    },
    [pathname],
  );

  const onClickToModify = useCallback(({ ...args }) => {
    postFetcher('admin/notice/edit', { ...args }, () => navigate('/notice', { state: { query } }));
  }, []);

  const onClickCancel = useCallback(() => navigate(-1), []);

  const { data } = useFetcher(`admin/${list === 'notice' ? list : 'qna'}/${id}`);

  const baseProps = {
    data: data ? data.data : undefined,
    id,
    name,
    type,
    onClickList,
    onClickToCreate,
    onClickToModify,
    onClickCancel,
  };

  return <Component {...baseProps} />;
}
