import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { TableInfoList } from '@comp/common/table/TableHeadList';
import { SearchListType } from '@comp/search/SearchTypes';
import { RowBody } from '@comp/common/table/styles';

import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';

function SearchListTableData({ faces }: { faces: SearchListType['faces'] }) {
  const TableInfo = TableInfoList.search;

  return (
    <>
      {faces.length > 0 ? (
        faces.map((face) => {
          return (
            <RowBody tableInfo={TableInfo} key={face.id}>
              <div>{face.id}</div>
              <div>{face.name}</div>
              <div>{face.createdAt.slice(0, 10).replaceAll('-', '.')}</div>
              <div>
                <Link to='detail' state={{ id: face.id }}>
                  <HashWrap id='hash'>
                    <span>{face.hash}</span>
                  </HashWrap>
                </Link>
              </div>
              <div>{face.ip}</div>
            </RowBody>
          );
        })
      ) : (
        <RowBody tableInfo={TableInfo} className='empty'>
          내역이 없습니다.
        </RowBody>
      )}
    </>
  );
}

const HashWrap = styled.div`
  &#hash {
    padding: 0 ${pixelToRem(120)};
    border-right: none;

    & > span {
      color: ${theme.color.blue};
    }
  }
`;

export default memo(SearchListTableData);
