export type SearchListType = {
  faces: {
    id: number;
    name: string;
    createdAt: string;
    hash: string;
    ip: string;
  }[];
  totalCount: number;
  filter: {
    name: string;
    startDate: string;
    endDate: string;
    page: number;
    pageSize: number;
  };
};

export const initialSearchList: SearchListType = {
  faces: [],
  totalCount: 1,
  filter: {
    name: '',
    startDate: '',
    endDate: '',
    page: 1,
    pageSize: 10,
  },
};

export type SearchDetailType = {
  faces: {
    id: number;
    imageUrl: string;
    target: string;
    fromUrl: string;
    region: string;
    percentage: number;
  }[];
  totalCount: number;
  id: number;
  name: string;
  createdAt: string;
  hash: string;
  ip: string;
};

export const initialSearchDetail = {
  faces: [],
  totalCount: 0,
  id: 0,
  name: '',
  createdAt: '',
  hash: '',
  ip: '',
};
