import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import Header from '@comp/common/Header';
import Sidebar from '@comp/common/Sidebar';
import Footer from '@comp/common/Footer';

import Login from '@page/Login';
import Main from '@page/Main';
import SearchList from '@page/SearchList';
import SearchDetail from '@page/SearchDetail';
import ReportList from '@page/ReportList';
import ActionList from '@page/ActionList';
import UserList from '@page/UserList';
import QnaList from '@page/QnaList';
import QnaDetail from '@page/QnaDetail';
import QnaReply from '@page/QnaReply';
import NoticeList from '@page/NoticeList';
import NoticeDetail from '@page/NoticeDetail';
import NoticeEdit from '@page/NoticeEdit';

import { LayoutGrid, ContentGrid, InnerFlex } from './layout';

function App() {
  const { pathname } = useLocation();
  return (
    <div>
      {pathname !== '/' ? (
        <>
          <Header />
          <LayoutGrid>
            <Sidebar />
            <ContentGrid>
              <InnerFlex>
                <Routes>
                  <Route path='main' element={<Main />} />
                  <Route path='search' element={<SearchList />} />
                  <Route path='search/detail' element={<SearchDetail />} />
                  <Route path='report' element={<ReportList />} />
                  <Route path='action' element={<ActionList />} />
                  <Route path='payment' element={<ActionList />} />
                  <Route path='charge' element={<ActionList />} />
                  <Route path='usage' element={<ActionList />} />
                  <Route path='refund' element={<ActionList />} />
                  <Route path='user' element={<UserList />} />
                  <Route path='qna' element={<QnaList />} />
                  <Route path='qna/:qnaId' element={<QnaDetail />} />
                  <Route path='reply' element={<QnaReply />} />
                  <Route path='notice' element={<NoticeList />} />
                  <Route path='notice/edit' element={<NoticeEdit />} />
                  <Route path='notice/:noticeId' element={<NoticeDetail />} />
                </Routes>
                <Footer />
              </InnerFlex>
            </ContentGrid>
          </LayoutGrid>
        </>
      ) : (
        <Login />
      )}
    </div>
  );
}

export default App;
