import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import $axios from '@config/axios';

import Modal from '@comp/common/modal/Modal';
import Swal from 'sweetalert2';

import useModal from '@hook/useModal';
import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';
import Logo from '@static/images/metuAdminLogo.png';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { isShow, onShow, onNoShow } = useModal();

  const isValidEmail = useCallback((): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailCondition = emailRegex.test(email);
    if (emailCondition) return true;
    return false;
  }, [email]);

  const onChangeEmail = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }, []);

  const onChangePassword = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }, []);

  const onKeyup = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onClick();
    }
  };

  const onClick = () => {
    if (isValidEmail()) {
      $axios
        .post('admin/account/login', {
          email,
          password,
        })
        .then((res) => {
          if (res && res.data) {
            const status = res.data.status;
            if (status === 200) {
              const { token } = res.data.data;
              localStorage.setItem('adminToken', token);
              navigate('/main');
            }
            if (status === 401) {
              onShow();
            }
            if (status === 500) {
              onShow();
            }
          }
        })
        .catch(() => {
          Swal.fire('서버 에러');
        });
    } else {
      onShow();
    }
  };

  return (
    <>
      <Modal
        isShow={isShow}
        h1Text='입력하신 계정 정보를 확인해주세요'
        firstBtn={{ isDisplay: true, content: '확인', onClick: onNoShow }}
        secondBtn={{ isDisplay: false }}
      ></Modal>
      <Background>
        <Wrap>
          <LogoImg src={Logo} alt='logo' />
          <LoginDesc>
            <p>디지털 성범죄 피해 영상/사진 검색 플랫폼입니다</p>
            <p>어드민 계정로그인 해주세요</p>
          </LoginDesc>
          <LoginInput
            type='text'
            placeholder='아이디(이메일)'
            value={email}
            onChange={onChangeEmail}
          />
          <LoginInput
            type='password'
            placeholder='비밀번호'
            value={password}
            onChange={onChangePassword}
            onKeyUp={onKeyup}
          />
          <LoginButton onClick={onClick}>로그인</LoginButton>
        </Wrap>
      </Background>
    </>
  );
}

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #244895;
`;

const Wrap = styled.div`
  width: ${pixelToRem(511)};
  height: ${pixelToRem(612)};
  padding: ${pixelToRem(26)};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: ${pixelToRem(12)};
  background-color: #fff;
  color: ${theme.color.gray808285};
  font-size: ${pixelToRem(20)};
`;

const LogoImg = styled.img`
  width: 60%;
`;

const LoginDesc = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${pixelToRem(8)};
`;

const LoginInput = styled.input`
  width: 100%;
  height: ${pixelToRem(60)};
  padding: ${pixelToRem(12)} 0;
  border: none;
  border-bottom: 1px solid ${theme.border.gray808285};
  font-size: ${pixelToRem(20)};
`;

const LoginButton = styled.button`
  width: ${pixelToRem(460)};
  height: ${pixelToRem(70)};
  margin-top: ${pixelToRem(8)};
  border: none;
  border-radius: ${pixelToRem(35)};
  background-color: #244895;
  color: #fff;
  font-size: ${pixelToRem(23)};
  cursor: pointer;
`;
