/* eslint-disable operator-linebreak */
import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import * as XLSX from 'xlsx';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid } from 'recharts';
import dayjs from 'dayjs';

import { useFetcher } from '@hook/useFetcher';
import $axios from '@config/axios';

import Dropdown from '@comp/common/Dropdown';

import { pixelToRem } from '@util/commonStyles';
import { theme } from '@util/theme';
import { initialMainGraph } from '@comp/main/MainTypes';
import { PageTitleWrap } from '@src/layout';

interface DataItemType {
  createdAt: string;
  count?: number;
  price?: string;
}

interface DataType {
  [key: string]: DataItemType;
}

type ExcelDataType = string | number;

const now = dayjs(new Date());

const getYearMonthArr = (now: dayjs.Dayjs, limit: number = 12): dayjs.Dayjs[] => {
  const arr = [now];
  for (let i = 1; i < limit; i++) {
    arr.push(now.add(-i, 'month'));
  }
  return arr;
};

const getOptions = (yearMonthArr: dayjs.Dayjs[]) => {
  return yearMonthArr.map((yearMonth) => yearMonth.format('YYYY년 M월'));
};

const yearMonthArr = getYearMonthArr(now);
const options = getOptions(yearMonthArr);

export default function MainStatistics() {
  const [currentTab, setCurrentTab] = useState(0);
  const [graphData, setGraphData] =
    useState<{ [key: string]: string | number | null }[]>(initialMainGraph);

  const tabs = [
    { visit: '방문자수' },
    { search: '검색요청' },
    { noReport: '신고요청' },
    { report: '신고완료' },
    { charge: '충전금액' },
    { refund: '환불금액' },
  ];

  const [index, setIndex] = useState(0);
  const yearMonth = yearMonthArr[index];
  const urlString = `admin/main/analyze?target=${
    Object.keys(tabs[currentTab])[0]
  }&year=${yearMonth.year()}&month=${yearMonth.month() + 1}`;

  const { data } = useFetcher(urlString);

  useEffect(() => {
    if (data) {
      const newGraphData = getGraphData(data.data.datas);
      setGraphData(newGraphData);
    }
  }, [data]);

  const onDropdownClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    const newIndex = options.indexOf(target.innerText);
    setIndex(newIndex);
  }, []);

  const onTabClick = useCallback((index: number) => {
    setCurrentTab(index);
  }, []);

  const toStatDateString = useCallback((DateString: string) => {
    const monthString = DateString.split('-')[1];
    const dateString = DateString.split('-')[2];
    return `${monthString}.${dateString}`;
  }, []);

  const getPeriodString = useCallback(() => {
    const lastDate =
      yearMonth.year() === now.year() && yearMonth.month() === now.month() && now.date() !== 1
        ? now.add(-1, 'day').date()
        : yearMonth.year() === now.year() && yearMonth.month() === now.month() && now.date() === 1
        ? 1
        : yearMonth.daysInMonth();

    const startString = `${yearMonth.format('YYYY.MM')}.01`;

    const endString = `${yearMonth.format('YYYY.MM')}.${lastDate.toString().padStart(2, '0')}`;

    return `${startString} - ${endString}`;
  }, [yearMonth]);

  const arrangeResData = useCallback((data: DataType) => {
    const dataObj: { [key: string]: number } = {};
    Object.keys(data).forEach((key: keyof DataType) => {
      dataObj[data[key].createdAt] = data[key].count
        ? Number(data[key].count as number)
        : Number(data[key].price as string);
    });
    return dataObj;
  }, []);

  const getGraphData = useCallback(
    (data: DataType) => {
      const lastDate = yearMonth.daysInMonth();
      const dataObj = arrangeResData(data);

      const graphData: { [key: string]: string | number | null }[] = [];
      for (let i = 0; i < lastDate; i++) {
        const dateString = `${yearMonth.format('YYYY-MM')}-${(i + 1).toString().padStart(2, '0')}`;
        const row: { [key: string]: string | number | null } = {
          date: toStatDateString(dateString),
        };
        row.amount = Object.keys(dataObj).includes(dateString)
          ? dataObj[dateString]
          : yearMonth === now && now.date() - 1 < i + 1
          ? null
          : 0;
        graphData.push(row);
      }
      return graphData;
    },
    [yearMonth],
  );

  const exportExcel = useCallback(async () => {
    const lastDate = yearMonth.daysInMonth();
    const excelData: ExcelDataType[][] = [];
    for (let i = 0; i < lastDate; i++) {
      const dateString = `${yearMonth.format('YYYY-MM')}-${(i + 1).toString().padStart(2, '0')}`;
      excelData.push([dateString]);
    }

    const types = ['visit', 'search', 'noReport', 'report', 'charge', 'refund'];

    for (let i = 0; i < types.length; i++) {
      const urlString = `admin/main/analyze?target=${types[i]}&year=${yearMonth.year()}&month=${
        yearMonth.month() + 1
      }`;
      const typeData = await $axios.get(urlString).then((res) => res.data.data.datas);
      const dataObj = arrangeResData(typeData);

      excelData.forEach((row) => {
        row.push(Object.keys(dataObj).includes(row[0] as string) ? dataObj[row[0]] : 0);
      });
    }

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([
      ['날짜', '방문자수', '검색요청', '신고요청', '신고완료', '충전금액', '환불금액'],
      ...excelData,
    ]);
    XLSX.utils.book_append_sheet(wb, ws, `${yearMonth.format('YYYY년 MM월')}`);
    XLSX.writeFile(wb, `${yearMonth.format('YYYY년 MM월')}.xlsx`);
  }, [yearMonth]);

  const periodString = getPeriodString();

  return (
    <Wrap>
      <StatHeader>
        <h1>월간통계</h1>
        <StatControl>
          <Dropdown options={options} current={yearMonth} onClick={onDropdownClick} />
          <ExcelDownloadButton onClick={exportExcel}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='18'
              height='16.714'
              viewBox='0 0 18 16.714'
            >
              <defs>
                <linearGradient
                  id='linear-gradient'
                  x1='0.174'
                  y1='-146.919'
                  x2='0.826'
                  y2='-145.787'
                  gradientUnits='objectBoundingBox'
                >
                  <stop offset='0' stopColor='#18884f' />
                  <stop offset='0.5' stopColor='#117e43' />
                  <stop offset='1' stopColor='#0b6631' />
                </linearGradient>
              </defs>
              <g id='file_type_excel_icon_130611' transform='translate(-2 -3)'>
                <path
                  id='패스_1177'
                  data-name='패스 1177'
                  d='M15.628,14.654,8.512,13.4v9.263a.766.766,0,0,0,.767.766H21.557a.766.766,0,0,0,.768-.766h0V19.25Z'
                  transform='translate(-2.326 -3.714)'
                  fill='#185c37'
                />
                <path
                  id='패스_1178'
                  data-name='패스 1178'
                  d='M15.628,3H9.279a.766.766,0,0,0-.767.766h0V7.179l7.116,4.179L19.4,12.611l2.93-1.254V7.179Z'
                  transform='translate(-2.326 0)'
                  fill='#21a366'
                />
                <path
                  id='패스_1179'
                  data-name='패스 1179'
                  d='M8.512,9.5h7.116v4.179H8.512Z'
                  transform='translate(-2.326 -2.321)'
                  fill='#107c41'
                />
                <path
                  id='패스_1180'
                  data-name='패스 1180'
                  d='M13.6,8.2H8.512V18.646H13.6a.771.771,0,0,0,.768-.766V8.966A.771.771,0,0,0,13.6,8.2Z'
                  transform='translate(-2.326 -1.857)'
                  opacity='0.1'
                />
                <path
                  id='패스_1181'
                  data-name='패스 1181'
                  d='M13.186,8.85H8.512V19.3h4.674a.771.771,0,0,0,.768-.766V9.616a.771.771,0,0,0-.768-.766Z'
                  transform='translate(-2.326 -2.089)'
                  opacity='0.2'
                />
                <path
                  id='패스_1182'
                  data-name='패스 1182'
                  d='M13.186,8.85H8.512v9.611h4.674a.771.771,0,0,0,.768-.766V9.616a.771.771,0,0,0-.768-.766Z'
                  transform='translate(-2.326 -2.089)'
                  opacity='0.2'
                />
                <path
                  id='패스_1183'
                  data-name='패스 1183'
                  d='M12.768,8.85H8.512v9.611h4.256a.771.771,0,0,0,.768-.766V9.616a.771.771,0,0,0-.768-.766Z'
                  transform='translate(-2.326 -2.089)'
                  opacity='0.2'
                />
                <path
                  id='패스_1184'
                  data-name='패스 1184'
                  d='M2.768,8.85h7.674a.767.767,0,0,1,.768.766v7.662a.767.767,0,0,1-.768.766H2.768A.766.766,0,0,1,2,17.277V9.616a.766.766,0,0,1,.768-.766Z'
                  transform='translate(0 -2.089)'
                  fill='url(#linear-gradient)'
                />
                <path
                  id='패스_1185'
                  data-name='패스 1185'
                  d='M5.7,17.107l1.614-2.5L5.836,12.127H7.023l.807,1.59a3.091,3.091,0,0,1,.153.337h.011c.053-.121.109-.237.167-.351l.863-1.573h1.093L8.6,14.6l1.555,2.51H8.992L8.06,15.366a1.514,1.514,0,0,1-.11-.235H7.935a1.085,1.085,0,0,1-.108.226l-.96,1.75Z'
                  transform='translate(-1.321 -3.26)'
                  fill='#fff'
                />
                <path
                  id='패스_1186'
                  data-name='패스 1186'
                  d='M25.511,3h-5.93V7.179h6.7V3.766A.766.766,0,0,0,25.511,3Z'
                  transform='translate(-6.279 0)'
                  fill='#33c481'
                />
                <path
                  id='패스_1187'
                  data-name='패스 1187'
                  d='M19.581,16h6.7v4.179h-6.7Z'
                  transform='translate(-6.279 -4.643)'
                  fill='#107c41'
                />
              </g>
            </svg>{' '}
            통계 엑셀 다운로드
          </ExcelDownloadButton>
        </StatControl>
      </StatHeader>
      <TabWrap>
        {tabs.map((tab, index) => {
          return (
            <Tab
              key={Object.keys(tab)[0]}
              selected={index === currentTab}
              onClick={() => onTabClick(index)}
            >
              {Object.values(tab)[0]}
            </Tab>
          );
        })}
      </TabWrap>
      <Viewer>
        <StatPeriod>
          {periodString}{' '}
          {yearMonth.year() === now.year() &&
            yearMonth.month() === now.month() &&
            now.date() !== 1 &&
            '(어제)'}
        </StatPeriod>
        <ResponsiveContainer minHeight={250}>
          <LineChart data={graphData} margin={{ top: 0, right: 20, bottom: 0, left: 0 }}>
            <CartesianGrid strokeDasharray='3' vertical={false} />
            <XAxis
              padding={{ left: 16, right: 16 }}
              interval={0}
              tick={{ fontSize: pixelToRem(13) }}
              tickLine={false}
              dataKey='date'
            />
            <YAxis
              width={40}
              dataKey='amount'
              tick={{ fontSize: pixelToRem(13) }}
              interval='preserveStart'
            />
            <Line type='monotone' dataKey='amount' stroke='#F07070' isAnimationActive={false} />
          </LineChart>
        </ResponsiveContainer>
      </Viewer>
    </Wrap>
  );
}

const Wrap = styled(PageTitleWrap)`
  h1 {
    padding: ${pixelToRem(24)} ${pixelToRem(23)} ${pixelToRem(15)};
    border-bottom: none;
    font-weight: 500;
  }
`;

const StatHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StatControl = styled.div`
  padding-right: ${pixelToRem(13)};
  display: flex;
  align-items: center;
  gap: ${pixelToRem(16)};
`;

const ExcelDownloadButton = styled.button`
  height: ${pixelToRem(40)};
  padding: ${pixelToRem(12)} ${pixelToRem(16)};
  display: flex;
  align-items: center;
  gap: ${pixelToRem(8)};
  border: 1px solid ${theme.border.gray707070};
  background-color: ${theme.background.white};
  font-size: ${pixelToRem(15)};

  &:hover {
    background-color: ${theme.background.mainColor};
    color: ${theme.border.white};
  }
`;

const TabWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Tab = styled.div<{ selected: boolean }>`
  flex-grow: 1;
  padding: ${pixelToRem(20)} 0 ${pixelToRem(18)} 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${pixelToRem(15)};
  border: ${({ selected }) => (selected ? 'none' : '1px solid #CCCCCC')};
  border-top: ${({ selected }) => (selected ? '2px solid #404040' : '1px solid #CCCCCC')};
  border-left: ${({ selected }) => selected && '1px solid #CCCCCC'};
  border-right: none;
  background-color: ${({ selected }) => (selected ? theme.background.white : '#FAFAFC')};
  cursor: pointer;

  &:first-child {
    border-left: none;
  }
`;

const Viewer = styled.div`
  padding: ${pixelToRem(25)} ${pixelToRem(23)};
  display: flex;
  flex-direction: column;
  gap: ${pixelToRem(17)};
`;

const StatPeriod = styled.div`
  color: ${theme.color.lightBlack};
  font-size: ${pixelToRem(12)};
  opacity: 0.5;
`;
