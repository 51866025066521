import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import $axios from '@config/axios';

import PageTitle from '@comp/common/PageTitle';
import ButtonContainer from '@comp/common/ButtonContainer';
import Button from '@comp/common/Button';
import Table from '@comp/common/table/Table';
import FilterItem from '@comp/common/FilterItem';
import Pagination from '@comp/common/Pagination';

import ConditionDetail from '@comp/user/ConditionDetail';
import UserTableData from '@comp/user/UserTableData';

import { ListLayout, FilterBox, InnerList, PageTitleWrap, SectionTitle } from '@src/layout';

import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';
import { stringToQuery } from '@util/string';

import { RootState } from '@reducer/index';
import userListSlice from '@reducer/user/userDataList';
import inputDataSlice from '@reducer/common/inputData';
import userPhoneSlice from '@reducer/user/userPhoneInput';
import userBirthSlice from '@reducer/user/userBirthInput';
import detailFilterSlice from '@reducer/user/detailFilter';
import isSearchSlice from '@reducer/common/isSearch';

function getIsVerified(isActive: string) {
  switch (isActive) {
    case '인증':
      return '1';
    case '미인증':
      return '0';
    default:
      return '';
  }
}

function getIsBlocked(isActive: string) {
  switch (isActive) {
    case '차단':
      return '1';
    case '미차단':
      return '0';
    default:
      return '';
  }
}

function getIdCard(isActive: string) {
  switch (isActive) {
    case '등록':
      return '1';
    case '미등록':
      return '0';
    default:
      return '';
  }
}

const UserList = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const navigate = useNavigate();

  const name = useSelector((state: RootState) => state.inputData.inputData);
  const phone = useSelector((state: RootState) => state.phoneInput.phone);
  const birth = useSelector((state: RootState) => state.birthInput.birth);
  const dataList = useSelector((state: RootState) => state.userList.list);
  const isVerified = useSelector((state: RootState) => state.detailFilter.isVerified);
  const idCard = useSelector((state: RootState) => state.detailFilter.idCard);
  const isBlocked = useSelector((state: RootState) => state.detailFilter.isBlocked);
  const isSearch = useSelector((state: RootState) => state.isSearch.isSearch);

  const [totalCount, setTotalCount] = useState(0);
  const [title, setTitle] = useState('전체 유저');

  const fetchGetUserList = useCallback(
    async (
      query: {
        page?: number;
        name?: string;
        phone?: string;
        isBlocked?: '0' | '1' | '';
        isVerified?: '0' | '1' | '';
        isIdCard?: '0' | '1' | '';
        birth?: string;
      },
      isRedirect = false,
    ) => {
      const q = stringToQuery(search) as {
        page: number;
        name: string;
        phone: string;
        isBlocked: '0' | '1' | '';
        isVerified: '0' | '1' | '';
        isIdCard: '0' | '1' | '';
        birth: string;
      };
      let queryString = '';
      if (Object.values(q).length > 0) {
        queryString = [
          `page=${q.page || 1}`,
          `name=${q.name}`,
          `phone=${q.phone}`,
          `isBlocked=${q.isBlocked || ''}`,
          `isVerified=${q.isVerified || ''}`,
          `isIdCard=${q.isIdCard || ''}`,
          `birth=${q.birth || ''}`,
        ].join('&');
      }
      if (Object.values(query).length > 0) {
        queryString = [
          `page=${query.page || 1}`,
          `name=${query.name}`,
          `phone=${query.phone}`,
          `isBlocked=${query.isBlocked || ''}`,
          `isVerified=${query.isVerified || ''}`,
          `isIdCard=${query.isIdCard || ''}`,
          `birth=${query.birth || ''}`,
        ].join('&');
      }
      if (isRedirect) navigate(`/user?${queryString}`);
      await $axios.get(`admin/user?${queryString}`).then((res) => {
        if (res && res.data && res.data.status === 200) {
          const { data } = res.data;
          dispatch(userListSlice.actions.setDataList(data.users));
          setTotalCount(data.totalCount);
        }
      });
    },
    [name, phone, birth, isVerified, isBlocked, idCard],
  );

  const onSearch = useCallback(() => {
    dispatch(isSearchSlice.actions.onSearch(true));
    fetchGetUserList(
      {
        name,
        phone,
        birth,
        isVerified: getIsVerified(isVerified.isActive),
        isBlocked: getIsBlocked(isBlocked.isActive),
        isIdCard: getIdCard(idCard.isActive),
      },
      true,
    );
    setTitle('유저');
  }, [name, phone, birth, isVerified, isBlocked, idCard]);

  const onPaging = useCallback(
    (page: number) => {
      fetchGetUserList(
        {
          name,
          phone,
          isVerified: getIsVerified(isVerified.isActive),
          isBlocked: getIsBlocked(isBlocked.isActive),
          isIdCard: getIdCard(idCard.isActive),
          page,
        },
        true,
      );
    },
    [fetchGetUserList],
  );

  const onReset = useCallback(() => {
    dispatch(detailFilterSlice.actions.setReset());
    dispatch(inputDataSlice.actions.onReset());
    dispatch(userPhoneSlice.actions.onReset());
    dispatch(userBirthSlice.actions.onReset());
    dispatch(isSearchSlice.actions.onSearch(false));
    fetchGetUserList({});
    navigate('/user');
    setTitle('전체 유저');
  }, []);

  useEffect(() => {
    onReset();
  }, []);
  return (
    <>
      <PageTitleWrap>
        <PageTitle />
        <FilterBox>
          <FilterItem width={260} padding={`${pixelToRem(5)} 0 0 0`} radius={0} hasSearch={true} />
          <ConditionDetail />
        </FilterBox>
        <ButtonContainer padding={`${pixelToRem(20)} ${pixelToRem(27)}`}>
          <Button
            fontSize={16}
            width={133}
            padding={`${pixelToRem(10)} 0`}
            align={'center'}
            bgColor={`${theme.background.mainColor}`}
            fontColor={`${theme.color.white}`}
            borderColor={`1px solid ${theme.background.mainColor}`}
            margin={`0 ${pixelToRem(15)} 0 0`}
            onClick={onSearch}
            isActiveBtn={isSearch}
          >
            검색
          </Button>
          <Button
            fontSize={16}
            width={133}
            padding={`${pixelToRem(10)} 0`}
            align={'center'}
            bgColor={`${theme.background.white}`}
            borderColor={`1px solid ${theme.border.mainColor}`}
            fontColor={`${theme.color.mainColor}`}
            onClick={() => onReset()}
          >
            초기화
          </Button>
        </ButtonContainer>
      </PageTitleWrap>
      <ListLayout>
        <SectionTitle>
          {title} 목록 <span>( {totalCount} 명 )</span>
        </SectionTitle>
        <InnerList>
          <Table>
            <UserTableData
              users={dataList}
              totalCount={totalCount}
              page={+stringToQuery(search).page || 1}
            />
          </Table>
          <Pagination
            totalCount={totalCount}
            page={+stringToQuery(search).page || 1}
            setPage={onPaging}
          />
        </InnerList>
      </ListLayout>
    </>
  );
};
export default memo(UserList);
