import React, { memo, useState } from 'react';
import styled from 'styled-components';
import { VscTriangleDown, VscTriangleUp } from 'react-icons/vsc';

import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';

import SearchFilter from './SearchFilter';

function ConditionDetail() {
  const [isActive, setActive] = useState(false);
  const onToggle = () => {
    setActive(!isActive);
  };
  return (
    <>
      <SlideAddDetail className={isActive ? 'active' : ''}>
        <SlideButton onClick={onToggle} className={isActive ? 'active' : ''}>
          상세조건
          {isActive ? <VscTriangleUp /> : <VscTriangleDown />}
        </SlideButton>
      </SlideAddDetail>
      {isActive ? <SearchFilter hasTab={true} /> : ''}
    </>
  );
}
const SlideAddDetail = styled.div`
  border-bottom: none;
  margin-bottom: 0;
  &.active {
    margin-bottom: ${pixelToRem(20)};
    border-bottom: 1px solid ${theme.border.gray};
  }
`;
const SlideButton = styled.button`
  display: flex;
  align-items: center;
  font-size: ${pixelToRem(15)};
  padding: ${pixelToRem(20)} 0 ${pixelToRem(10)} 0;
  background-color: transparent;
  border: none;
  &.active {
    padding: ${pixelToRem(20)} 0;
  }
  svg {
    margin-left: ${pixelToRem(10)};
  }
`;
export default memo(ConditionDetail);
