import React, { memo, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Button from '@comp/common/Button';
import Modal from '@comp/common/modal/Modal';
import { TableInfoList } from '@comp/common/table/TableHeadList';
import { RowBody } from '@comp/common/table/styles';
import { NoticeListType } from '@comp/notice/NoticeTypes';

import { usePostFetcher } from '@hook/useFetcher';
import useModal from '@hook/useModal';

import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';

function NoticeTableData({ data, query }: { data: NoticeListType; query: any }) {
  const TableInfo = TableInfoList.notice;

  const [id, setId] = useState(-1);
  const navigate = useNavigate();
  const { isShow, onShow, onNoShow } = useModal();

  const { mutate } = usePostFetcher('admin/notice/delete', { id }, () => {
    window.location.reload();
  });

  const onClickModifyButton = useCallback((id: number) => {
    navigate('edit', { state: { id, query } });
  }, []);

  const onClickDeleteButton = useCallback((id: number) => {
    setId(id);
    onShow();
  }, []);

  const onClickToDelete = () => {
    mutate();
  };

  return (
    <>
      <Modal
        isShow={isShow}
        h1Text='해당 공지사항을 삭제하시겠습니까?'
        firstBtn={{ isDisplay: true, content: '확인', onClick: onClickToDelete }}
        secondBtn={{ isDisplay: true, content: '취소', onClick: onNoShow }}
      ></Modal>
      {data.notices.length > 0 ? (
        data.notices.map((notice) => (
          <RowBody tableInfo={TableInfo} key={notice.id}>
            <div>{notice.id}</div>
            <div>{notice.category === 'NORMAL' ? '일반' : '시스템'}</div>
            <div>{notice.createdAt.slice(0, 10).replaceAll('-', '.')}</div>
            <div>
              <Link to={`${notice.id}`} state={{ query }}>
                <NoticeTitle id='title'>{notice.title}</NoticeTitle>
              </Link>
            </div>
            <div>{notice.isPostFile ? '포함' : '미포함'}</div>
            <ButtonWrap>
              <Button
                width={85}
                height={40}
                padding={`0  ${pixelToRem(12)}`}
                bgColor={`${theme.color.white}`}
                borderColor={`1px solid ${theme.border.gray}`}
                fontSize={13}
                isHover
                hoverBorder='none'
                hoverBgColor={`${theme.background.mainColor}`}
                hoverColor={`${theme.color.white}`}
                onClick={() => onClickModifyButton(notice.id)}
              >
                수정
              </Button>
              <Button
                width={85}
                height={40}
                padding={`0  ${pixelToRem(12)}`}
                bgColor={`${theme.color.white}`}
                borderColor={`1px solid ${theme.border.gray}`}
                fontSize={13}
                isHover
                hoverBorder='none'
                hoverBgColor={`${theme.background.mainColor}`}
                hoverColor={`${theme.color.white}`}
                onClick={() => onClickDeleteButton(notice.id)}
              >
                삭제
              </Button>
            </ButtonWrap>
          </RowBody>
        ))
      ) : (
        <RowBody tableInfo={TableInfo} className='empty'>
          내역이 없습니다.
        </RowBody>
      )}
    </>
  );
}

const NoticeTitle = styled.span`
  &#title {
    color: ${theme.color.blue};
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  gap: ${pixelToRem(7)};
`;

export default memo(NoticeTableData);
