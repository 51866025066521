import React, { memo, useState, useEffect } from 'react';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import $axios from '@config/axios';

import CustomInput from '@comp/common/CustomInput';
import ButtonContainer from '@comp/common/ButtonContainer';
import Button from '@comp/common/Button';

import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';

import type { ModalProps } from './Modal';
import { ModalWrap } from './style';

function InfoModal({
  isShow,
  firstBtn = { isDisplay: true, content: '', onClick: () => {} },
  secondBtn = {
    isDisplay: true,
    content: '',
    onClick: () => {},
  },
}: ModalProps) {
  const InfoForm = [
    { thead: '아이디(이메일)', type: 'text', isDisable: true, value: 'sadmin@metu.ai' },
    { thead: '기존 비밀번호', type: 'password', isDisable: false, value: '' },
    { thead: '신규 비밀번호', type: 'password', isDisable: false, value: '' },
    { thead: '비밀번호 확인', type: 'password', isDisable: false, value: '' },
  ];

  const [pn, setPn] = useState('');
  const [newPn, setNewPn] = useState('');
  const [confirmPn, setConfirmPn] = useState('');

  const closeModal = () => {
    if (secondBtn.onClick) secondBtn.onClick();
    setPn('');
    setNewPn('');
    setConfirmPn('');
  };

  // TODO Change Password Post
  const fetchCheckPassword = async () => {
    if (newPn !== confirmPn) {
      Swal.fire({
        icon: 'warning',
        text: '입력하신 비밀번호가 일치하지 않습니다.',
      });
      return;
    }
    await $axios
      .post('admin/account/change/password', {
        email: 'sadmin@metu.ai',
        password: pn,
        newPasswd: confirmPn,
      })
      .then((res) => {
        if (res && res.data && res.data.status === 200) {
          if (res.data.data.result) {
            closeModal();
          }
        }
        if (res && res.data && res.data.status === 401) {
          Swal.fire({
            icon: 'warning',
            title: '서버 에러',
            text: res.data.msg,
          });
        }
      })
      .catch((err) => {
        const { data } = err;
        Swal.fire({
          icon: 'error',
          title: '서버 에러',
          text: data.msg,
        });
      });
  };

  const defaultPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPn(e.target.value);
  };

  const newPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPn(e.target.value);
  };

  const confirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPn(e.target.value);
  };

  function passwordConfirm(thead: string, event: React.ChangeEvent<HTMLInputElement>) {
    switch (thead) {
      case '기존 비밀번호':
        return defaultPassword(event);
      case '신규 비밀번호':
        return newPassword(event);
      case '비밀번호 확인':
        return confirmPassword(event);
      default:
        return 'sadmin@metu.ai';
    }
  }

  function getInputValue(thead: string, value: string) {
    switch (thead) {
      case '기존 비밀번호':
        return pn;
      case '신규 비밀번호':
        return newPn;
      case '비밀번호 확인':
        return confirmPn;
      default:
        return value;
    }
  }

  useEffect(() => {
    setPn('');
    setNewPn('');
    setConfirmPn('');
  }, []);

  return (
    <>
      {isShow ? (
        <ModalWrap>
          <InfoModalBox>
            <h1>계정 정보 수정</h1>
            <InfoWrap>
              {InfoForm.map((info, index) => (
                <InputBox key={index}>
                  <p>{info.thead}</p>
                  <CustomInput
                    type={info.type}
                    width={280}
                    padding={`${pixelToRem(12)} ${pixelToRem(20)}`}
                    isDisabled={info.isDisable}
                    value={getInputValue(info.thead, info.value)}
                    onChange={(e) => {
                      passwordConfirm(info.thead, e);
                    }}
                  />
                </InputBox>
              ))}
            </InfoWrap>
            <ButtonContainer
              padding={`${pixelToRem(20)} ${pixelToRem(30)}`}
              align={'right'}
              border={`1px solid ${theme.border.gray}`}
            >
              <Button
                padding={`${pixelToRem(10)} ${pixelToRem(20)}`}
                fontColor={`${theme.color.gray707070}`}
                margin={`0 ${pixelToRem(10)} 0 0`}
                borderColor={`1px solid ${theme.border.gray}`}
                isHover={true}
                hoverBgColor={`${theme.background.mainColor}`}
                hoverColor={`${theme.color.white}`}
                hoverBorder={`1px solid ${theme.border.mainColor}`}
                onClick={fetchCheckPassword}
              >
                {firstBtn.content}
              </Button>
              <Button
                padding={`${pixelToRem(10)} ${pixelToRem(20)}`}
                fontColor={`${theme.color.gray707070}`}
                margin={`0 ${pixelToRem(10)} 0 0`}
                borderColor={`1px solid ${theme.border.gray}`}
                isHover={true}
                hoverBgColor={`${theme.background.mainColor}`}
                hoverColor={`${theme.color.white}`}
                hoverBorder={`1px solid ${theme.border.mainColor}`}
                onClick={closeModal}
              >
                {secondBtn.content}
              </Button>
            </ButtonContainer>
          </InfoModalBox>
        </ModalWrap>
      ) : null}
    </>
  );
}

const InfoModalBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${theme.background.white};
  h1 {
    font-size: ${pixelToRem(20)};
    padding: ${pixelToRem(22)} ${pixelToRem(33)};
    border-bottom: 1px solid ${theme.border.gray};
  }
`;
const InfoWrap = styled.div`
  padding: ${pixelToRem(25)} ${pixelToRem(65)};
`;
const InputBox = styled.div`
  display: flex;
  margin-bottom: ${pixelToRem(10)};
  align-items: center;
  p {
    flex-basis: ${pixelToRem(184)};
    vertical-align: middle;
    color: ${theme.color.gray707070};
  }
`;

export default memo(InfoModal);
