import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { ListLayout, FilterBox, InnerList, PageTitleWrap, SectionTitle } from '@src/layout';

import $axios from '@config/axios';

import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';
import { stringToQuery } from '@util/string';

import PageTitle from '@comp/common/PageTitle';
import FilterItem from '@comp/common/FilterItem';
import ButtonContainer from '@comp/common/ButtonContainer';
import Button from '@comp/common/Button';
import Table from '@comp/common/table/Table';
import Pagination from '@comp/common/Pagination';

import ReportTableData from '@comp/report/ReportTableData';

import { RootState } from '@reducer/index';
import inputDataSlice from '@reducer/common/inputData';
import reportListSlice from '@reducer/report/reportDataList';
import reportFilterSlice from '@reducer/report/reportFilter';
import periodFilterSlice from '@reducer/common/periodFilter';
import activeTabSlice from '@reducer/common/activeTab';
import isSearchSlice from '@reducer/common/isSearch';

function setIsReported(tabName: string): string {
  switch (tabName) {
    case '신고요청':
      return '0';
    case '신고완료':
      return '1';
    default:
      return '';
  }
}

function getReportTitle(tabName: string) {
  switch (tabName) {
    case '신고요청':
      return '신고 요청';
    case '신고완료':
      return '신고 완료';
    default:
      return '전체 신고';
  }
}

function getStateTitle(isReported: string) {
  if (isReported === '0') {
    return '신고 요청';
  } else {
    return '신고 완료';
  }
}

export default function ReportList() {
  const dispatch = useDispatch();
  const { search, state }: { search: string; state: any } = useLocation();
  const navigate = useNavigate();

  const tabName = useSelector((state: RootState) => state.reportFilter.tabName);
  const name = useSelector((state: RootState) => state.inputData.inputData);
  const dataList = useSelector((state: RootState) => state.reportList.list);
  const { startDate: startdate, endDate: enddate } = useSelector(
    (state: RootState) => state.periodFilter,
  );
  const isSearch = useSelector((state: RootState) => state.isSearch.isSearch);

  const [title, setTitle] = useState('전체 신고');
  const [totalCount, setTotalCount] = useState(0);

  const fetchGetReportList = useCallback(
    async (
      query: {
        page?: number;
        isreported?: string;
        startdate?: string;
        enddate?: string;
        name?: string;
      },
      isRedirect = false,
    ) => {
      const q = stringToQuery(search) as {
        page: number;
        isreported: string;
        startdate: string;
        enddate: string;
        name: string;
      };

      let queryString = '';
      if (Object.values(q).length > 0) {
        queryString = [
          `page=${q.page || 1}`,
          `name=${q.name}`,
          `startdate=${q.startdate}`,
          `enddate=${q.enddate}`,
          `isreported=${q.isreported}`,
        ].join('&');
      }
      if (Object.values(query).length > 0) {
        queryString = [
          `page=${query.page || 1}`,
          `name=${query.name}`,
          `startdate=${query.startdate}`,
          `enddate=${query.enddate}`,
          `isreported=${query.isreported}`,
        ].join('&');
      }
      if (isRedirect) navigate(`/report?${queryString}`);
      setTitle(getReportTitle(tabName));
      await $axios.get(`admin/report/list?${queryString}`).then((res) => {
        if (res && res.data && res.data.status === 200) {
          const { data } = res.data;
          dispatch(reportListSlice.actions.setDataList(data.reports));
          setTotalCount(data.totalCount);
        }
      });
    },
    [tabName, name, startdate, enddate],
  );

  const onSearch = useCallback(() => {
    fetchGetReportList({ name, startdate, enddate, isreported: setIsReported(tabName) }, true);
    dispatch(isSearchSlice.actions.onSearch(true));
  }, [tabName, name, startdate, enddate]);

  const onPaging = useCallback(
    (page: number) => {
      fetchGetReportList(
        { name, startdate, enddate, isreported: setIsReported(tabName), page },
        true,
      );
    },
    [fetchGetReportList],
  );

  const onReset = useCallback(() => {
    dispatch(reportFilterSlice.actions.onIsActive('전체'));
    dispatch(reportListSlice.actions.setReset());
    dispatch(inputDataSlice.actions.onReset());
    dispatch(periodFilterSlice.actions.onReset());
    dispatch(activeTabSlice.actions.setReset());
    dispatch(isSearchSlice.actions.onSearch(false));
    fetchGetReportList({});
    navigate('/report');
    setTitle('전체 신고');
  }, []);

  useEffect(() => {
    if (state) {
      dispatch(periodFilterSlice.actions.onUpdate({ periodName: '1주일', startDate: '' }));
      fetchGetReportList(state);
      setTitle(getStateTitle(state.isreported));
      return;
    }
    onReset();
  }, []);

  return (
    <>
      <PageTitleWrap>
        <PageTitle />
        <FilterBox>
          <FilterItem hasInput={true} theadText='사용자' margin={`0 0 ${pixelToRem(10)} 0`} />
          <FilterItem theadText='조회기간' margin={`0 0 ${pixelToRem(10)} 0`} hasPeriod={true} />
          <FilterItem theadText='상세조건' hasTab={true} />
        </FilterBox>
        <ButtonContainer padding={`${pixelToRem(20)} ${pixelToRem(27)}`}>
          <Button
            fontSize={16}
            width={133}
            padding={`${pixelToRem(10)} 0`}
            align={'center'}
            bgColor={`${theme.background.mainColor}`}
            fontColor={`${theme.color.white}`}
            borderColor={`1px solid ${theme.background.mainColor}`}
            margin={`0 ${pixelToRem(15)} 0 0`}
            onClick={() => onSearch()}
            isActiveBtn={isSearch}
          >
            검색
          </Button>
          <Button
            fontSize={16}
            width={133}
            padding={`${pixelToRem(10)} 0`}
            align={'center'}
            bgColor={`${theme.background.white}`}
            borderColor={`1px solid ${theme.border.mainColor}`}
            fontColor={`${theme.color.mainColor}`}
            onClick={() => onReset()}
          >
            초기화
          </Button>
        </ButtonContainer>
      </PageTitleWrap>
      <ListLayout>
        <SectionTitle>
          {title} 목록 <span>( {totalCount.toLocaleString('ko')}건 )</span>
        </SectionTitle>
        <InnerList>
          <Table>
            <ReportTableData
              reports={dataList}
              totalCount={totalCount}
              page={+stringToQuery(search).page || 1}
            />
          </Table>
          <Pagination
            totalCount={totalCount}
            page={+stringToQuery(search).page || 1}
            setPage={onPaging}
          />
        </InnerList>
      </ListLayout>
    </>
  );
}
