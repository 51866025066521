import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { TabWrap } from '@src/layout';

import { RootState } from '@reducer/store';
import activeTabSlice from '@reducer/common/activeTab';

import Button from '@comp/common/Button';
import { TabList } from '@comp/TabList';

import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';
import actionFilterSlice from '@reducer/action/acionFilter';
import reportFilterSlice from '@reducer/report/reportFilter';
import isSearchSlice from '@reducer/common/isSearch';

function TabButton() {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentUrl = location.pathname.split('/').filter(Boolean)[0];
  const activeTab = useSelector((state: RootState) => state.activeTab.activeTab);

  return (
    <TabWrap>
      {TabList[
        ['action', 'refund', 'payment', 'usage', 'charge'].includes(currentUrl)
          ? 'action'
          : currentUrl
      ].map((i) => (
        <Button
          margin={`0 ${pixelToRem(3)} 0 0`}
          fontColor={`${theme.color.black}`}
          padding={`${pixelToRem(11)} 0`}
          width={113}
          fontSize={15}
          borderColor={`1px solid ${theme.border.gray}`}
          isHover={true}
          hoverBgColor={theme.background.mainColor}
          hoverColor={theme.color.white}
          hoverBorder={`1px solid ${theme.border.mainColor}`}
          key={i.id}
          onClick={() => {
            dispatch(activeTabSlice.actions.setActiveTab(i.tabName));
            dispatch(isSearchSlice.actions.onSearch(false));
            if (['action', 'refund', 'payment', 'usage', 'charge'].includes(i.tab)) {
              dispatch(actionFilterSlice.actions.onIsActive(i.tabName));
            } else if (currentUrl === 'report') {
              dispatch(reportFilterSlice.actions.onIsActive(i.tabName));
            }
          }}
          isActiveBtn={activeTab === i.tabName}
        >
          {i.tabName}
        </Button>
      ))}
    </TabWrap>
  );
}

export default memo(TabButton);
