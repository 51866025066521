import React from 'react';
import styled from 'styled-components';

import ButtonContainer from '@comp/common/ButtonContainer';

import { StyledButton } from '@comp/common/StyledButton';
import { pixelToRem } from '@util/commonStyles';

interface FooterButtonItemProps {
  type: 'button' | 'submit' | 'reset' | undefined;
  text: string;
  onClick: () => void;
}

interface FooterButtonProps {
  list: FooterButtonItemProps;
  submit?: FooterButtonItemProps;
  cancel?: FooterButtonItemProps;
}

export function FooterButtonItem(props: FooterButtonItemProps) {
  const { type, text, onClick } = props;
  return (
    <StyledButton type={type} onClick={onClick}>
      {text}
    </StyledButton>
  );
}

export default function FooterButton({ list, submit, cancel }: FooterButtonProps) {
  return (
    <ButtonContainer
      padding={`${pixelToRem(20)} ${pixelToRem(27)}`}
      display='flex'
      justifyContent='space-between'
    >
      <ButtonLeft>
        <FooterButtonItem {...list} />
      </ButtonLeft>
      <ButtonRight>
        {submit && <FooterButtonItem {...submit} />}
        {cancel && <FooterButtonItem {...cancel} />}
      </ButtonRight>
    </ButtonContainer>
  );
}

const ButtonLeft = styled.div``;

const ButtonRight = styled.div`
  display: flex;
  gap: ${pixelToRem(17)};
`;
