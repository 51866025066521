import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type UserDataListProps = {
  list: any[];
};
export const initialState: UserDataListProps = {
  list: [],
};

const userListSlice = createSlice({
  name: 'userList',
  initialState,
  reducers: {
    setUserBlocked: (state, action: PayloadAction<{ idx: number; isBlocked: boolean }>) => {
      state.list[action.payload.idx].isBlocked = action.payload.isBlocked;
    },
    setDataList: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setReset: (state) => {
      state.list = [];
    },
  },
});
export default userListSlice;
