/* eslint-disable function-paren-newline */
import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFetcher, usePostFetcher } from '@hook/useFetcher';
import { NoticeDetailType } from '@comp/notice/NoticeTypes';
import { QnaDetailType } from '@comp/qna/QnaTypes';

type DetailType = NoticeDetailType | QnaDetailType;

export type BasePropsType<T extends DetailType> = {
  data: T | undefined;
  name: string;
  onClickList: () => void;
  onClickEdit: () => void;
  onClickDelete: () => void;
};

export function withDetail<T extends DetailType>(
  Component: (props: BasePropsType<T>) => JSX.Element,
) {
  const navigate = useNavigate();
  const { pathname, state }: { pathname: string; state: any } = useLocation();

  const type = pathname.split('/')[1];
  const id = pathname.split('/')[2];
  const name = state ? state.name : '';
  const query = state ? state.query : {};

  const { mutate: mutateDelete } = usePostFetcher('admin/notice/delete', { id }, () =>
    navigate('/notice'),
  );

  const onClickList = useCallback(() => {
    navigate(`/${type}`, { state: { query } });
  }, [type]);

  const onClickEdit = useCallback(() => {
    if (type === 'notice') {
      navigate(`/${type}/edit`, { state: { id, query } });
    } else if (type === 'qna') {
      navigate('/reply', { state: { id, name, query } });
    }
  }, [type]);

  const onClickDelete = useCallback(() => mutateDelete(), [id]);

  const { data } = useFetcher(`admin/${type}/${id}`);


  const baseProps = {
    data: data ? data.data : undefined,
    name,
    onClickList,
    onClickEdit,
    onClickDelete,
  };

  return <Component {...baseProps} />;
}
