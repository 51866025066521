/* eslint-disable operator-linebreak */
import React, { memo, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Datepicker, { DateObject } from 'react-multi-date-picker';
import InputIcon from 'react-multi-date-picker/components/input_icon';
import 'dayjs/locale/ko';

import type { RootState } from '@reducer/store';
import periodFilterSlice from '@reducer/common/periodFilter';

import { stringToQuery } from '@util/string';

import './styles.css';
import ko from './ko';

type SelectDatepicker = {
  singleDate?: boolean;
};

function Calendar({ singleDate = false }: SelectDatepicker) {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const { startDate, endDate } = useSelector((state: RootState) => state.periodFilter);

  const onMultiChange = useCallback(
    (selectedDates: DateObject[]) => {
      if (selectedDates.length === 2) {
        const [start, end] = selectedDates;
        const { year: startYear, month: startMonth, day: startDay } = start;
        const { year: endYear, month: endMonth, day: endDay } = end;

        dispatch(
          periodFilterSlice.actions.onUpdate({
            periodName: '',
            startDate: `${startYear}-${String(startMonth).padStart(2, '0')}-${String(
              startDay,
            ).padStart(2, '0')}`,
            endDate: `${endYear}-${String(endMonth).padStart(2, '0')}-${String(endDay).padStart(
              2,
              '0',
            )}`,
          }),
        );
      }
    },
    [dispatch, periodFilterSlice, search],
  );

  useEffect(() => {
    const query = stringToQuery(search);
    if (Object.values(query).length > 0) {
      dispatch(
        periodFilterSlice.actions.onUpdate({
          periodName: '',
          startDate: query.startdate,
          endDate: query.enddate,
        }),
      );
    }
  }, [dispatch]);

  return (
    <Datepicker
      locale={ko}
      format='YYYY-MM-DD'
      numberOfMonths={singleDate ? 1 : 2}
      value={[startDate, endDate] as string[]}
      onChange={onMultiChange}
      range={!singleDate}
      render={<InputIcon />}
    ></Datepicker>
  );
}

export default memo(Calendar);
