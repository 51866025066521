import React from 'react';
import styled from 'styled-components';

import MainWeek from '@comp/main/MainWeek';
import MainToday from '@comp/main/MainToday';
import MainNotice from '@comp/main/MainNotice';
import MainStatistics from '@comp/main/MainStatistics';
import PageTitle from '@comp/common/PageTitle';

import { theme } from '@util/theme';
import { pixelToRem } from '@src/utils/commonStyles';
import { today } from '@util/commonUtil';
import { PageTitleWrap } from '@src/layout';

export default function Main() {
  return (
    <>
      <MainHeaderWrap>
        <MainTitleWrap>
          <PageTitle />
          {today.add(-6, 'days').format('YYYY.MM.DD')} - {today.format('YYYY.MM.DD')} 기준
        </MainTitleWrap>
        <MainWeek />
      </MainHeaderWrap>
      <MainTodayNoticeWrap>
        <MainToday />
        <MainNotice />
      </MainTodayNoticeWrap>
      <MainStatistics />
    </>
  );
}
const MainHeaderWrap = styled(PageTitleWrap)`
  & h1 {
    color: ${theme.color.black};
    font-weight: 500;
    border-bottom: none;
  }
`;

const MainTitleWrap = styled.div`
  padding-right: ${pixelToRem(24.5)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${theme.border.gray};
  color: ${theme.color.gray808285};
`;

const MainTodayNoticeWrap = styled.div`
  display: flex;
  gap: ${pixelToRem(20)};
`;
