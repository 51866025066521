import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type IsSearchState = {
  isSearch: boolean;
};

const initialState: IsSearchState = {
  isSearch: false,
};

const isSearchSlice = createSlice({
  name: 'isSearch',
  initialState,
  reducers: {
    onSearch: (state, action: PayloadAction<boolean>) => {
      state.isSearch = action.payload;
    },
  },
});

export default isSearchSlice;
