import styled from 'styled-components';

import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';

export const ResultTitle = styled.h2`
  font-size: ${pixelToRem(18)};
  color: ${theme.color.lightBlack};
  font-weight: bold;
  margin-bottom: ${pixelToRem(20)};
`;

export const ResultList = styled.div`
  width: 100%;
  background-color: ${theme.background.semiWhite};
  padding: ${pixelToRem(20)} ${pixelToRem(25)};
  border: 1px solid ${theme.border.gray};
  margin-bottom: ${pixelToRem(20)};
  display: flex;
  justify-content: space-between;
`;

export const ItemWrap = styled.div`
  flex: 1;
`;

export const ListItem = styled.ul`
  display: inline-block;
  vertical-align: middle;
  &::after {
    content: '|';
    font-size: ${pixelToRem(14)};
    display: inline-block;
    vertical-align: moddle;
    color: ${theme.color.gray707070};
    margin: 0 ${pixelToRem(10)};
  }

  &:last-child::after {
    display: none;
  }
  &.active li {
    font-weight: bolder;
    color: ${theme.color.black};
  }
  li {
    display: inline-block;
    vertical-align: middle;
    font-size: ${pixelToRem(14)};
    color: ${theme.color.gray707070};
    &:first-child {
      margin-right: ${pixelToRem(15)};
      font-weight: 800;
    }
  }
`;
export const ResultTotal = styled.ul`
  text-align: right;
  li {
    display: inline-block;
    vertical-align: middle;
  }
  li:first-child {
    font-size: ${pixelToRem(14)};
    border-left: 2px solid ${theme.border.gray707070};
    padding-left: ${pixelToRem(15)};
  }
  li:last-child {
    padding-left: ${pixelToRem(15)};
    font-size: ${pixelToRem(20)};
    color: ${theme.color.blue};
    font-weight: bolder;
  }
`;
