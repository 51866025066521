import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type actionPaymentProps = {
  userId: number;
  name: string;
  price: number;
  receiptId: string;
};
export const initialState: actionPaymentProps = {
  userId: 0,
  name: '',
  price: 0,
  receiptId: '',
};

const actionPaymentSlice = createSlice({
  name: 'actionPayment',
  initialState,
  reducers: {
    onSelect: (state: actionPaymentProps, action: PayloadAction<actionPaymentProps>) => {
      state.userId = action.payload.userId;
      state.name = action.payload.name;
      state.price = action.payload.price;
      state.receiptId = action.payload.receiptId;
    },
  },
});

export default actionPaymentSlice;
