import React, { useState, useEffect, memo } from 'react';
import { useLocation } from 'react-router-dom';

import { TableWrap, RowHead } from './styles';
import { TableInfoList } from './TableHeadList';

type TableProps = {
  children: React.ReactNode;
  target?: string;
};

function Table({ children, target }: TableProps) {
  const { pathname } = useLocation();
  const path: string = pathname.substring(1);
  const [TableInfo, setTableInfo] = useState(TableInfoList[target || path]);

  useEffect(() => {
    setTableInfo(TableInfoList[target || path]);
  }, [target, pathname, path]);

  return (
    <TableWrap>
      <RowHead tableInfo={TableInfo}>
        {TableInfo.thead.map((thead, index) => (
          <div key={index}>{thead}</div>
        ))}
      </RowHead>
      {children}
    </TableWrap>
  );
}

export default memo(Table);
