import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type ReportDataListProps = {
  list: any[];
};
export const initialState: ReportDataListProps = {
  list: [],
};

const reportListSlice = createSlice({
  name: 'reportList',
  initialState,
  reducers: {
    setDataList: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setReset: (state) => {
      state.list = [];
    },
  },
});

export default reportListSlice;
