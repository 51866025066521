import React, { memo } from 'react';
import styled from 'styled-components';

import { TableInfoList } from '@comp/common/table/TableHeadList';
import { RowBody } from '@comp/common/table/styles';
import { SearchDetailType } from '@comp/search/SearchTypes';
// import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';

function SearchDetailTableData(props: { data: SearchDetailType }) {
  const { faces, name } = props.data;
  const TableInfo = TableInfoList['search/detail'];

  return (
    <>
      {faces && faces.length > 0 ? (
        faces.map((face) => {
          return (
            <RowBody tableInfo={TableInfo} key={face.id}>
              <div>{face.id}</div>
              <ImageWrap id='image'>
                <Image src={face.imageUrl} alt='image' />
              </ImageWrap>
              <div>{name}</div>
              <div>{face.target}</div>
              <UrlWrap>
                <span>{face.fromUrl}</span>
                {/* <HoverWrap>
                  <div>{face.fromUrl}</div>
                </HoverWrap> */}
              </UrlWrap>
              <div>{face.region}</div>
              <div>{face.percentage}</div>
            </RowBody>
          );
        })
      ) : (
        <RowBody tableInfo={TableInfo} className='empty'>
          내역이 없습니다.
        </RowBody>
      )}
    </>
  );
}
const ImageWrap = styled.div`
  &#image {
    padding: 0;
  }
`;

const Image = styled.img`
  width: ${pixelToRem(43)};
  height: ${pixelToRem(43)};
`;

const UrlWrap = styled.div`
  position: relative;

  span {
    width: 80%;
  }
  div {
    border: none;
  }
`;

// const HoverWrap = styled.div`
//   position: absolute;
//   top: 50%;
//   z-index: 9999;
//   padding-top: ${pixelToRem(12)};
//   visibility: hidden;
//   opacity: 0;
//   transition: opacity 0.1s ease-in-out;
//   white-space: nowrap;

//   span:hover + &,
//   &:hover {
//     border-right: none;
//     visibility: visible;
//     opacity: 1;
//   }

//   & > div {
//     padding: ${pixelToRem(8)};
//     background-color: ${theme.background.white};
//     border: 1px solid black;
//   }
// `;

export default memo(SearchDetailTableData);
