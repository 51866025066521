import React from 'react';
import { useSelector } from 'react-redux';

import ButtonContainer from '@comp/common/ButtonContainer';
import { StyledButton } from '@comp/common/StyledButton';

import { RootState } from '@reducer/store';

import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';

interface FilterButtonProps {
  onClickSearch: () => void;
  onClickReset: () => void;
}

export default function FilterButton({ onClickSearch, onClickReset }: FilterButtonProps) {
  const isSearch = useSelector((state: RootState) => state.isSearch.isSearch);
  return (
    <ButtonContainer padding={`${pixelToRem(20)} ${pixelToRem(27)}`}>
      <StyledButton
        type='submit'
        onClick={onClickSearch}
        margin={`0 ${pixelToRem(15)} 0 0`}
        isActiveBtn={isSearch}
      >
        검색
      </StyledButton>
      <StyledButton
        type='button'
        onClick={onClickReset}
        borderColor={`1px solid ${theme.border.mainColor}`}
        fontColor={`${theme.color.mainColor}`}
      >
        초기화
      </StyledButton>
    </ButtonContainer>
  );
}
