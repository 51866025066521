import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useFetcher } from '@hook/useFetcher';
import { NoticeListType } from '@comp/notice/NoticeTypes';
import { QnaListType } from '@comp/qna/QnaTypes';
import { SearchListType, SearchDetailType } from '@comp/search/SearchTypes';
import activeTabSlice from '@reducer/common/activeTab';
import inputDataSlice from '@reducer/common/inputData';
import periodFilterSlice from '@reducer/common/periodFilter';
import isSearchSlice from '@reducer/common/isSearch';
import { today } from '@util/commonUtil';

type ListType = NoticeListType | QnaListType | SearchListType | SearchDetailType;

export type BasePropsType<T extends ListType> = {
  state: any;
  data: T | undefined;
  page: number;
  setQuery: React.Dispatch<React.SetStateAction<{ [key: string]: string | number }>>;
  onClickSearch: () => void;
  onClickReset: () => void;
  onClickPage: (num: number) => void;
};

type QueryType = {
  [key: string]: string | number;
};

export function withList<T extends ListType>(Component: (props: BasePropsType<T>) => JSX.Element) {
  const dispatch = useDispatch();
  const { pathname, state }: { pathname: string; state: any } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState<QueryType>({ page: 1 });

  useEffect(() => {
    if (state && state.query) {
      setSearchParams(state.query);
    }

    const keyword = searchParams.get('keyword');
    if (keyword) {
      dispatch(inputDataSlice.actions.onSetName(keyword));
    }

    const isreply = searchParams.get('isreply');
    if (isreply === 'true') {
      dispatch(activeTabSlice.actions.setActiveTab('답변'));
    }
    if (isreply === 'false') {
      dispatch(activeTabSlice.actions.setActiveTab('미답변'));
    }

    const cate = searchParams.get('cate');
    if (cate === 'NORMAL') {
      dispatch(activeTabSlice.actions.setActiveTab('일반'));
    }
    if (cate === 'SYSTEM') {
      dispatch(activeTabSlice.actions.setActiveTab('시스템'));
    }

    if (!isreply && !cate) {
      dispatch(activeTabSlice.actions.setReset());
    }

    const startDateQuery = searchParams.get('startdate');
    const endDateQuery = searchParams.get('enddate');
    if (startDateQuery && endDateQuery) {
      const startDate = dayjs(startDateQuery);
      const endDate = dayjs(endDateQuery);
      const diffDay = startDate.diff(endDate, 'day');
      const diffMonth = startDate.diff(endDate, 'month', true);

      let periodName = '';
      if (endDateQuery === today.format('YYYY-MM-DD') && diffDay === 0) {
        periodName = '오늘';
      }
      if (endDateQuery === today.format('YYYY-MM-DD') && diffDay === -6) {
        periodName = '1주일';
      }
      if (endDateQuery === today.format('YYYY-MM-DD') && diffMonth === -1) {
        periodName = '1개월';
      }
      if (endDateQuery === today.format('YYYY-MM-DD') && diffMonth === -3) {
        periodName = '3개월';
      }
      dispatch(
        periodFilterSlice.actions.onUpdate({
          periodName,
          startDate: startDateQuery,
          endDate: endDateQuery,
        }),
      );
    }

    if (!isreply && !cate && !startDateQuery) {
      dispatch(isSearchSlice.actions.onSearch(false));
    } else {
      dispatch(isSearchSlice.actions.onSearch(true));
    }
  }, [searchParams]);

  const type = pathname.split('/')[1];
  const page = Number(searchParams.get('page')) || 1;

  const getQuery = useCallback((params: URLSearchParams) => {
    let q = '?';
    params.forEach((value, key) => {
      q += `${key}=${value}&`;
    });
    return q !== '?' ? q.slice(0, -1) : '';
  }, []);

  const getNewQuery = useCallback((q: QueryType) => {
    const newQ = Object.keys(q)
      .map((key) => `${key}=${q[key]}`)
      .join('&');
    return `${newQ ? `?${newQ}` : ''}`;
  }, []);

  const getUrlString = useCallback(() => {
    let string = `admin/${type}`;
    if (state && state.id) {
      string += `/${state.id}`;
    }
    string += getQuery(searchParams);
    return string;
  }, [type, state, searchParams]);

  const onClickSearch = () => {
    dispatch(isSearchSlice.actions.onSearch(true));
    setSearchParams(getNewQuery(query));
  };

  const onClickReset = useCallback(() => {
    dispatch(inputDataSlice.actions.onSetName(''));
    dispatch(activeTabSlice.actions.setReset());
    dispatch(periodFilterSlice.actions.onReset());
    dispatch(isSearchSlice.actions.onSearch(false));
    setSearchParams('');
  }, []);

  const onClickPage = useCallback(
    (num: number) => {
      searchParams.set('page', `${num}`);
      setSearchParams(searchParams);
    },
    [searchParams],
  );

  const urlString = getUrlString();
  const { data } = useFetcher(urlString);

  const baseProps = {
    state,
    data: data ? data.data : undefined,
    page,
    setQuery,
    onClickSearch,
    onClickReset,
    onClickPage,
  };

  return <Component {...baseProps} />;
}
