import React, { memo } from 'react';
import styled from 'styled-components';

import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';

// NOTE : Button 컴포넌트와 같은 맥락입니다.
export type InputStyleProps = {
  type?: string;
  padding?: string;
  margin?: string;
  borderColor?: string;
  width?: number;
  height?: number;
  fontSize?: number;
  fontColor?: string;
  children?: React.ReactNode;
  theadText?: string;
  radius?: number;
  border?: string;
  borderBottom?: string;
  placeholder?: string;
  verticalAlign?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void | any;
  isDisabled?: boolean;
};

function CustomInput({
  type = 'text',
  padding,
  margin,
  radius,
  border,
  borderColor,
  width,
  height,
  fontSize,
  fontColor,
  placeholder,
  verticalAlign,
  children,
  value,
  onChange,
  isDisabled = false,
}: InputStyleProps) {
  return (
    <Input
      border={border}
      radius={radius}
      padding={padding}
      margin={margin}
      width={width}
      height={height}
      type={type}
      borderColor={borderColor}
      fontSize={fontSize}
      fontColor={fontColor}
      verticalAlign={verticalAlign}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabled={isDisabled}
      className={isDisabled ? 'disabled' : ''}
    >
      {children ?? null}
    </Input>
  );
}

export const Input = styled.input<InputStyleProps>`
  padding: ${({ padding }) => padding ?? '0'};
  margin: ${({ margin }) => margin ?? '0'};
  border: ${({ border }) => border ?? `1px solid ${theme.border.gray}`};
  border-radius: ${({ radius }) => (radius ? pixelToRem(radius) : 0)};
  border-color: ${({ borderColor }) => borderColor ?? 'none'};
  width: ${({ width }) => (width ? pixelToRem(width) : '100%')};
  height: ${({ height }) => (height ? pixelToRem(height) : '100%')};
  font-size: ${({ fontSize }) => (fontSize ? pixelToRem(fontSize) : pixelToRem(16))};
  color: ${({ fontColor }) => fontColor ?? `${theme.color.black}`};
  vertical-align: ${({ verticalAlign }) => verticalAlign ?? 'middle'};
  &.disabled {
    background-color: ${theme.background.grayf1f1f1};
  }
`;

export default memo(CustomInput);
