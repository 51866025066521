import React, { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import $axios from '@config/axios';

import Button from '@comp/common/Button';
import Modal from '@comp/common/modal/Modal';
import { RowBody } from '@comp/common/table/styles';
import { TableInfoList } from '@comp/common/table/TableHeadList';

import useModal from '@hook/useModal';

import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';

import { RootState } from '@reducer/index';
import userListSlice from '@reducer/user/userDataList';
import userManagementSlice from '@reducer/user/userManagement';

import type { UserType } from './UserTypes';

type UserTableDataProps = Pick<UserType, 'users'>;
type TotalUserProps = UserTableDataProps & {
  totalCount: number;
  page: number;
};

function UserTableData({ users, totalCount, page }: TotalUserProps) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { isShow, onShow, onNoShow } = useModal();
  const [isBlocked, setIsBlocked] = useState(false);
  const userId = useSelector((state: RootState) => state.userManagement.userId);
  const userName = useSelector((state: RootState) => state.userManagement.userName);
  const currentUrl: string = pathname.split('/')[1];
  const TableInfo = TableInfoList[currentUrl];

  const isBlockModal = (id: number, name: string, isBlocked: boolean) => {
    onShow();
    setIsBlocked(!!isBlocked);
    dispatch(userManagementSlice.actions.setTypeUser({ userId: id, userName: name }));
  };

  const isBlockPost = useCallback(
    async (userId: number, onNoShow: () => void) => {
      await $axios.post('admin/user/block', { userId, isBlocked: !isBlocked }).then((res) => {
        if (res && res.data && res.data.status === 200) {
          const idx = users.findIndex((user) => user.id === userId);
          dispatch(userListSlice.actions.setUserBlocked({ idx, isBlocked: !isBlocked }));
          onNoShow();
        }
      });
    },
    [isBlocked, onNoShow],
  );

  return (
    <>
      <Modal
        isShow={isShow}
        h1Text={`${userName} 고객님의 차단여부를 변경하시겠습니까?`}
        firstBtn={{
          isDisplay: true,
          content: '확인',
          onClick: () => isBlockPost(userId as number, onNoShow),
        }}
        secondBtn={{ isDisplay: true, content: '취소', onClick: onNoShow }}
      />
      {users.length > 0 ? (
        users.map((userData, index) => (
          <RowBody tableInfo={TableInfo} key={index}>
            <div>{totalCount - index - 10 * (page - 1)}</div>
            <div>{userData.name}</div>
            <div>{userData.pn.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, '$1-$2-$3')}</div>
            <div>{userData.birth.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1.$2.$3')}</div>
            <div>{userData.isVerified ? '인증' : '미인증'}</div>
            <div>
              <span>{userData.idCard ? '등록' : '미등록'}</span>
            </div>
            <div>{userData.isBlocked ? '차단' : '미차단'}</div>
            <div>{userData.createdAt.split(' ')[0].replaceAll('-', '.')}</div>
            <div>
              <Button
                margin={`0 ${pixelToRem(7)} 0 0`}
                fontColor={`${theme.color.black}`}
                padding={`${pixelToRem(11)} 0`}
                fontSize={14}
                width={85}
                borderColor={`1px solid ${theme.border.gray}`}
                isHover={true}
                hoverBgColor={theme.background.mainColor}
                hoverColor={theme.color.white}
                hoverBorder={`1px solid ${theme.border.mainColor}`}
                onClick={() => {
                  isBlockModal(userData.id, userData.name, !!userData.isBlocked);
                }}
              >
                {userData.isBlocked ? '차단해제' : '차단'}
              </Button>
            </div>
          </RowBody>
        ))
      ) : (
        <RowBody tableInfo={TableInfo} className='empty'>
          내역이 없습니다.
        </RowBody>
      )}
    </>
  );
}
export default memo(UserTableData);
