import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type userManagementProps = {
  userId?: number;
  userName: string;
};

export const initialState: userManagementProps = {
  userId: 0,
  userName: '',
};

const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    setTypeUser: (state, action: PayloadAction<userManagementProps>) => {
      state.userId = action.payload.userId;
      state.userName = action.payload.userName;
    },
  },
});

export default userManagementSlice;
