import React, { memo, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';
import { stringToQuery } from '@util/string';

import CustomInput from '@comp/common/CustomInput';
import TabButton from '@comp/common/TabButton';
import SearchFilter from '@comp/user/SearchFilter';
import PeriodFilter from '@comp/report/PeriodFilter';

import { RootState } from '@reducer/index';
import inputDataSlice from '@reducer/common/inputData';

import type { InputStyleProps } from './CustomInput';

type ItemContainerStyleProps = InputStyleProps & {
  margin?: string;
  padding?: string;
  alignItems?: string;
  hasInput?: boolean;
  hasTab?: boolean;
  hasSearch?: boolean;
  hasPeriod?: boolean;
  children?: React.ReactNode | React.ReactNode[];
};

export const FilterContainer = styled.div<ItemContainerStyleProps>`
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || '0'};
  border-bottom: ${({ borderBottom }) => borderBottom || 'none'};
  display: flex;
  align-items: ${({ alignItems }) => alignItems || 'center'};
  font-size: 0;
  .theadBox {
    width: ${pixelToRem(100)};
  }
  .theadBox p {
    font-size: ${pixelToRem(15)};
    color: ${theme.color.lightBlack};
  }
  .inputWrap {
    width: calc(100% - 140px);
  }
  .tabWrap {
    width: calc(100% - 140px);
    margin-bottom: ${pixelToRem(10)};
  }
`;

function FilterItem({
  theadText,
  margin,
  padding,
  borderBottom,
  alignItems,
  hasInput = false,
  hasTab = false,
  hasSearch = false,
  hasPeriod = false,
  children,
}: ItemContainerStyleProps) {
  const { search } = useLocation();
  const dispatch = useDispatch();

  const value = useSelector((state: RootState) => state.inputData.inputData);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(inputDataSlice.actions.onSetName(e.target.value));
  };

  useEffect(() => {
    const { name } = stringToQuery(search as string);
    dispatch(inputDataSlice.actions.onSetName(name || ''));
  }, [dispatch, search]);

  return (
    <>
      <FilterContainer
        margin={margin}
        padding={padding}
        borderBottom={borderBottom}
        alignItems={alignItems}
      >
        <div className='theadBox'>
          <p>{theadText}</p>
        </div>
        {hasInput && (
          <CustomInput
            width={345}
            padding={`${pixelToRem(10)} ${pixelToRem(20)}`}
            radius={0}
            value={value}
            onChange={onChange}
          />
        )}
        {hasTab && <TabButton />}
        {children ?? null}
        {hasPeriod && <PeriodFilter />}
      </FilterContainer>
      {hasSearch && (
        <FilterContainer
          margin={margin}
          padding={padding}
          borderBottom={borderBottom}
          alignItems={alignItems}
        >
          <SearchFilter hasInput={true} />
        </FilterContainer>
      )}
    </>
  );
}

export default memo(FilterItem);
