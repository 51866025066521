import { PostFileType } from '@util/initialData';

export type NoticeTab = 'TOTAL' | 'NORMAL' | 'SYSTEM';

export type NoticeListType = {
  totalCount: number;
  notices: {
    id: number;
    category: string;
    createdAt: string;
    title: string;
    isPostFile: number;
  }[];
  filter: {
    category: string;
    keyword: string;
    startDate: string;
    endDate: string;
    page: number;
  };
};

export const initialNoticeList: NoticeListType = {
  totalCount: 0,
  notices: [],
  filter: {
    category: 'NORMAL',
    keyword: '',
    startDate: '',
    endDate: '',
    page: 1,
  },
};

export type NoticeDetailType = {
  notice: {
    id: number;
    adminId: number;
    category: 'NORMAL' | 'SYSTEM';
    title: string;
    content: string;
    isPostFile: 0;
    createdAt: string;
    postFiles?: PostFileType[];
  };
};

export const initialNoticeDetail: NoticeDetailType = {
  notice: {
    id: 0,
    adminId: 0,
    category: 'NORMAL',
    title: '',
    content: '',
    isPostFile: 0,
    createdAt: '',
  },
};
