import React, { memo, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import useModal from '@hook/useModal';

import logo from '@static/images/metuLogo.png';

import { HeaderWrap, LogoHead, AdminButton } from '@src/layout';

import InfoModal from '@comp/common/modal/InfoEditModal';

function Header() {
  const navigate = useNavigate();
  const { isShow, onShow, onNoShow } = useModal();

  const onClickLogout = useCallback(() => {
    localStorage.removeItem('adminToken');
    navigate('/');
  }, []);

  return (
    <>
      <InfoModal
        isShow={isShow}
        firstBtn={{ isDisplay: true, content: '확인' }}
        secondBtn={{ isDisplay: true, content: '닫기', onClick: onNoShow }}
      />
      <HeaderWrap>
        <LogoHead>
          <Link to='/main'>
            <img src={logo} alt='logo' />
          </Link>
        </LogoHead>
        <AdminButton>
          <li>(주)카라멜라 관리자</li>
          <li>
            <button onClick={onShow}>정보수정</button>
          </li>
          <li>
            <button onClick={onClickLogout}>로그아웃</button>
          </li>
        </AdminButton>
      </HeaderWrap>
    </>
  );
}

export default memo(Header);
