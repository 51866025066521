export type MainType = {
  weekData: {
    qna: {
      reply: number;
      noReply: number;
    };
    report: {
      report: number;
      noReport: number;
    };
    price: {
      charge: number;
      usage: number;
      refund: number;
      payment: number;
    };
    search: {
      request: number;
      requested: number;
    };
  };
  todayData: {
    report: {
      today: number;
      yesterday: number;
    };
    noReport: {
      today: number;
      yesterday: number;
    };
    charge: {
      today: number;
      yesterday: number;
    };
    refund: {
      today: number;
      yesterday: number;
    };
    search: {
      today: number;
      yesterday: number;
    };
  };
  notices: {
    id: 0;
    category: string;
    title: string;
  }[];
};

export const initialMain: MainType = {
  weekData: {
    qna: {
      reply: 0,
      noReply: 0,
    },
    report: {
      report: 0,
      noReport: 0,
    },
    price: {
      charge: 0,
      usage: 0,
      refund: 0,
      payment: 0,
    },
    search: {
      request: 0,
      requested: 0,
    },
  },
  todayData: {
    report: {
      today: 0,
      yesterday: 0,
    },
    noReport: {
      today: 0,
      yesterday: 0,
    },
    charge: {
      today: 0,
      yesterday: 0,
    },
    refund: {
      today: 0,
      yesterday: 0,
    },
    search: {
      today: 0,
      yesterday: 0,
    },
  },
  notices: [
    {
      id: 0,
      category: 'NORMAL',
      title: 'LOADING...',
    },
  ],
};

export type MainGraphItemType = {
  date: string;
  count: number;
};

export const initialMainGraph: MainGraphItemType[] = new Array(31).fill({
  date: '00.00',
  count: 0,
});
