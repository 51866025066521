import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { ActionTotal } from '@comp/action/ActionTypes';

export type tabNameProps = {
  tabName: keyof ActionTotal;
};
export const initialState: tabNameProps = {
  tabName: 'action',
};

const actionFilterSlice = createSlice({
  name: 'actionFilter',
  initialState,
  reducers: {
    onIsActive: (state: tabNameProps, action: PayloadAction<string>) => {
      // NOTE DEFAULT 값은 무조건 있어야 합니다.
      switch (action.payload) {
        case '결제':
          state.tabName = 'payment';
          break;
        case '충전':
          state.tabName = 'charge';
          break;
        case '소진':
          state.tabName = 'usage';
          break;
        case '환불':
          state.tabName = 'refund';
          break;
        default:
          state.tabName = 'action';
          break;
      }
    },
  },
});

export default actionFilterSlice;
