import { combineReducers, configureStore } from '@reduxjs/toolkit';

import sidebarSlice from '@reducer/common/sidebar';
import isSearchSlice from '@reducer/common/isSearch';

import actionFilterSlice from '@reducer/action/acionFilter';
import actionPaymentSlice from '@reducer/action/actionPayment';
import actionDataListSlice from '@reducer/action/actionDataList';
import actionTotalSlice from '@reducer/action/actionResult';

import reportListSlice from '@reducer/report/reportDataList';
import reportFilterSlice from '@reducer/report/reportFilter';
import periodFilterSlice from '@reducer/common/periodFilter';
import reportNameSlice from '@reducer/report/reportName';

import userListSlice from '@reducer/user/userDataList';
import userPhoneSlice from '@reducer/user/userPhoneInput';
import userBirthSlice from '@reducer/user/userBirthInput';
import userManagementSlice from '@reducer/user/userManagement';
import detailFilterSlice from '@reducer/user/detailFilter';

import inputDataSlice from './common/inputData';
import activeTabSlice from './common/activeTab';

export const rootReducer = combineReducers({
  [actionDataListSlice.name]: actionDataListSlice.reducer,
  [sidebarSlice.name]: sidebarSlice.reducer,
  [isSearchSlice.name]: isSearchSlice.reducer,

  [actionFilterSlice.name]: actionFilterSlice.reducer,
  [actionPaymentSlice.name]: actionPaymentSlice.reducer,
  [actionTotalSlice.name]: actionTotalSlice.reducer,

  [reportListSlice.name]: reportListSlice.reducer,
  [reportFilterSlice.name]: reportFilterSlice.reducer,
  [periodFilterSlice.name]: periodFilterSlice.reducer,
  [reportNameSlice.name]: reportNameSlice.reducer,

  [userListSlice.name]: userListSlice.reducer,
  [userPhoneSlice.name]: userPhoneSlice.reducer,
  [userBirthSlice.name]: userBirthSlice.reducer,
  [userManagementSlice.name]: userManagementSlice.reducer,
  [detailFilterSlice.name]: detailFilterSlice.reducer,

  [inputDataSlice.name]: inputDataSlice.reducer,
  [activeTabSlice.name]: activeTabSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});
export type RootState = ReturnType<typeof store.getState>;
