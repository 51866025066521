/* eslint-disable operator-linebreak */
import React, { useCallback } from 'react';
import styled from 'styled-components';

import useModal from '@hook/useModal';

import { withDetail, BasePropsType } from '@comp/common/wrapper/DetailWrapper';
import FooterButton from '@comp/common/FooterButton';
import { FileButton } from '@comp/common/StyledButton';
import Row from '@comp/common/Row';
import Modal from '@comp/common/modal/Modal';
import { initialNoticeDetail, NoticeDetailType } from '@comp/notice/NoticeTypes';

import { PageTitleWrap } from '@src/layout';

import { theme } from '@util/theme';
import { onClickFileButton } from '@util/commonUtil';
import { pixelToRem } from '@util/commonStyles';

function NoticeDetailBase(props: BasePropsType<NoticeDetailType>) {
  const { data, onClickList, onClickEdit, onClickDelete } = props;
  const noticeData = data ? data.notice : initialNoticeDetail.notice;

  const { isShow, onShow, onNoShow } = useModal();

  const getCreatedAtString = useCallback((createdAt: string) => {
    return createdAt.slice(0, 10).replaceAll('-', '.');
  }, []);

  return (
    <>
      <Modal
        isShow={isShow}
        h1Text='해당 공지사항을 삭제하시겠습니까?'
        firstBtn={{ isDisplay: true, content: '삭제', onClick: onClickDelete }}
        secondBtn={{ isDisplay: true, content: '취소', onClick: onNoShow }}
      ></Modal>
      <Wrap>
        <Header>
          <Title>
            <h1>
              {noticeData.category === 'NORMAL' ? '[일반] ' : '[시스템] '}
              {noticeData.title}
            </h1>
          </Title>
          <TitleRight>
            <span>등록일 : {getCreatedAtString(noticeData.createdAt || '')}</span>
          </TitleRight>
        </Header>
        <Body>
          <ContentWrap>
            <pre>{noticeData.content}</pre>
          </ContentWrap>
          <AttachmentWrap>
            <Row title='첨부파일'>
              {noticeData.postFiles &&
                noticeData.postFiles.map((file) => {
                  return (
                    <FileButton
                      key={file.id}
                      type='button'
                      onClick={() => onClickFileButton(file.Bucket!, file.Key!, file.name)}
                    >
                      {file.name}
                    </FileButton>
                  );
                })}
            </Row>
          </AttachmentWrap>
        </Body>
        <FooterButton
          list={{ type: 'button', text: '목록', onClick: onClickList }}
          submit={{ type: 'submit', text: '수정', onClick: onClickEdit }}
          cancel={{ type: 'button', text: '삭제', onClick: onShow }}
        />
      </Wrap>
    </>
  );
}

export default function NoticeDetail() {
  return withDetail<NoticeDetailType>(NoticeDetailBase);
}

const Wrap = styled(PageTitleWrap)`
  h1 {
    border-bottom: none;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${theme.border.gray};
`;

const Title = styled.div`
  width: 70%;
  h1 {
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const TitleRight = styled.div`
  padding-right: ${pixelToRem(43)};
  display: flex;
  align-items: center;
  gap: ${pixelToRem(20)};
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentWrap = styled.div`
  padding: ${pixelToRem(43)} ${pixelToRem(27)};
  border-bottom: 1px solid ${theme.border.gray};
`;

const AttachmentWrap = styled.div`
  padding: ${pixelToRem(17)} 0 ${pixelToRem(20)} 0;
  border-bottom: 1px solid ${theme.border.gray};
`;
