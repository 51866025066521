import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { initialMain, MainType } from '@comp/main/MainTypes';

import { useFetcher } from '@hook/useFetcher';
import MainWeekItem from '@comp/main/MainWeekItem';

export default function MainWeek() {
  const [weekData, setWeekData] = useState<MainType['weekData']>(initialMain.weekData);
  const { data } = useFetcher('admin/main');
 

  useEffect(() => {
    if (data) {
      setWeekData(data.data.weekData);
    }
  }, [data]);

  const { qna, report, price, search } = weekData;
  return (
    <Wrap>
      <MainWeekItem title='search' items={search} />
      <MainWeekItem title='report' items={report} />
      <MainWeekItem title='price' items={price} />
      <MainWeekItem title='qna' items={qna} />
    </Wrap>
  );
}

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
`;
