import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { withList, BasePropsType } from '@comp/common/wrapper/ListWrapper';
import PageTitle from '@comp/common/PageTitle';
import FilterItem from '@comp/common/FilterItem';
import FilterButton from '@comp/common/FilterButton';
import Table from '@comp/common/table/Table';
import Pagination from '@comp/common/Pagination';
import NoticeTableData from '@comp/notice/NoticeTableData';
import { NoticeListType, initialNoticeList } from '@comp/notice/NoticeTypes';

import { RootState } from '@reducer/store';

import { ListLayout, FilterBox, InnerList, PageTitleWrap, SectionTitle } from '@src/layout';

import { HiOutlinePencilAlt } from 'react-icons/hi';

import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';
import { todayString } from '@util/string';

const cateQuery = {
  전체: '',
  일반: 'NORMAL',
  시스템: 'SYSTEM',
};

function Base(props: BasePropsType<NoticeListType>) {
  const { state, data, page, setQuery, onClickSearch, onClickReset, onClickPage } = props;

  const keyword = useSelector((state: RootState) => state.inputData.inputData);
  const { startDate, endDate } = useSelector((state: RootState) => state.periodFilter);
  const tabName = useSelector((state: RootState) => state.activeTab.activeTab);

  const query = {
    keyword: state && state.query ? state.query.keyword : keyword.trim(),
    startdate: state && state.query ? state.query.startdate : startDate,
    enddate: state && state.query ? state.query.enddate : endDate || todayString,
    cate: state && state.query ? state.query.cate : cateQuery[tabName as keyof typeof cateQuery],
    page: state && state.query ? state.query.page : `${page}`,
  };

  useEffect(() => {
    setQuery(query);
  }, [keyword, startDate, endDate, tabName, page]);

  const noticeList = data || initialNoticeList;

  return (
    <>
      <PageTitleWrap>
        <PageTitle />
        <FilterBox>
          <FilterItem theadText='상세조건' hasTab margin={`0 0 ${pixelToRem(10)} 0`} />
          <FilterItem theadText='조회기간' hasPeriod margin={`0 0 ${pixelToRem(10)} 0`} />
          <FilterItem theadText='검색키워드' hasInput />
        </FilterBox>
        <FilterButton onClickSearch={onClickSearch} onClickReset={onClickReset} />
      </PageTitleWrap>
      <ListLayout>
        <SectionHeader>
          <SectionTitle>
            공지사항 목록 <span>({noticeList.totalCount.toLocaleString('ko')}건)</span>
          </SectionTitle>
          <Link to='edit' state={{ query }}>
            <RegisterButton>
              <HiOutlinePencilAlt
                size={22}
                style={{
                  color: `${theme.color.white}`,
                }}
              />{' '}
              새 공지사항 등록
            </RegisterButton>
          </Link>
        </SectionHeader>
        <InnerList>
          <Table>
            <NoticeTableData data={noticeList} query={query} />
          </Table>
          <Pagination totalCount={noticeList.totalCount} page={page} setPage={onClickPage} />
        </InnerList>
      </ListLayout>
    </>
  );
}

export default function NoticeList() {
  return withList<NoticeListType>(Base);
}

const SectionHeader = styled.div`
  padding-right: ${pixelToRem(26)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${theme.border.gray};
  h1 {
    border-bottom: none;
  }
`;

const RegisterButton = styled.button`
  height: ${pixelToRem(40)};
  padding: ${pixelToRem(12)} ${pixelToRem(16)};
  display: flex;
  align-items: center;
  gap: ${pixelToRem(8)};
  border: none;
  background-color: ${theme.background.mainColor};
  color: ${theme.color.white};
  font-size: ${pixelToRem(15)};
  font-weight: 500;
`;
