import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type tabNameProps = {
  tabName: string;
};
export const initialState: tabNameProps = {
  tabName: '전체',
};

const reportFilterSlice = createSlice({
  name: 'reportFilter',
  initialState,
  reducers: {
    onIsActive: (state: tabNameProps, action: PayloadAction<string>) => {
      state.tabName = action.payload;
    },
  },
});

export default reportFilterSlice;
