import React from 'react';
import Button, { ButtonStyleProps } from '@comp/common/Button';
import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';

export function StyledButton({ type, onClick, children, ...rest }: ButtonStyleProps) {
  const typeObj = {
    submit: {
      bgColor: theme.background.mainColor,
      fontColor: theme.color.white,
      borderColor: `1px solid ${theme.border.mainColor}`,
    },
    button: {
      bgColor: theme.background.white,
      fontColor: theme.color.black,
      borderColor: `1px solid ${theme.border.gray}`,
    },
  };
  return (
    <Button
      type={type}
      width={133}
      height={40}
      fontSize={15}
      padding={`${pixelToRem(10)} 0`}
      align={'center'}
      {...typeObj[type as keyof typeof typeObj]}
      {...rest}
      onClick={onClick}
    >
      {children ?? null}
    </Button>
  );
}

export function FileButton({ type, onClick, children, ...rest }: ButtonStyleProps) {
  const typeObj = {
    submit: {
      fontColor: theme.color.black,
    },
    button: {
      fontColor: theme.color.blue,
    },
  };
  return (
    <Button
      type={type}
      height={40}
      fontSize={13}
      margin={'0 3px 0 0'}
      padding={`0 ${pixelToRem(8)}`}
      align={'center'}
      bgColor={theme.background.white}
      borderColor={`1px solid ${theme.border.gray}`}
      {...typeObj[type as keyof typeof typeObj]}
      {...rest}
      onClick={onClick}
    >
      {children ?? null}
    </Button>
  );
}
