/* eslint-disable no-unused-vars */
import React, { memo } from 'react';
import styled from 'styled-components';
import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';

// NOTE : 기본적으로 Button에 들어가는 property들은 rest로도 표현이 가능합니다.
// NOTE : 그리고 스타일에 포함되는 props들과 기능하는 props들은 분리해서 지정해주는게 나중에 파악할 때도 좋습니다.
// NOTE : Example: ButtonStyleProps, ButtonProps 이렇게요
// NOTE : ButtonStyleProps는 ButtonProps에 extends 되구요
interface ButtonProps {
  type?: 'button' | 'submit' | 'reset';
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void | any;
  children?: React.ReactNode;
  value?: string;
  isActiveBtn?: boolean;
  isDisabled?: boolean;
}
export interface ButtonStyleProps extends ButtonProps {
  margin?: string;
  align?: string;
  bgColor?: string;
  fontSize?: number;
  fontColor?: string;
  borderColor?: string;
  width?: number;
  height?: number;
  padding?: string;
  fontWeight?: number;
  isHover?: boolean;
  hoverBgColor?: string;
  hoverColor?: string;
  hoverBorder?: string;
}
function Button({
  type = 'button',
  onClick,
  isDisabled,
  isActiveBtn,
  children,
  value,
  ...rest
}: ButtonStyleProps) {
  return (
    <ButtonContainer
      type={type}
      onClick={onClick}
      value={value}
      isActiveBtn={isActiveBtn}
      className={isActiveBtn ? 'active' : '' || isDisabled ? 'disabled' : ''}
      disabled={isDisabled}
      {...rest}
    >
      {children ?? null}
    </ButtonContainer>
  );
}

const ButtonContainer = styled.button<ButtonStyleProps>`
  margin: ${({ margin }) => margin ?? '0'};
  text-align: ${({ align }) => align ?? 'center'};
  background-color: ${({ bgColor }) => bgColor ?? 'transparent'};
  font-size: ${({ fontSize }) => (fontSize ? pixelToRem(fontSize) : pixelToRem(16))};
  color: ${({ fontColor }) => fontColor ?? `${theme.color.mainColor}`};
  border: ${({ borderColor }) => borderColor ?? 'none'};
  width: ${({ width }) => (width ? pixelToRem(width) : 'auto')};
  height: ${({ height }) => (height ? pixelToRem(height) : 'auto')};
  padding: ${({ padding }) => padding ?? '0'};
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  &:hover {
    background-color: ${({ isHover, hoverBgColor }) => isHover && hoverBgColor};
    color: ${({ isHover, hoverColor }) => isHover && hoverColor};
    border: ${({ isHover, hoverBorder }) => isHover && hoverBorder};
  }
  &.active {
    background-color: ${theme.background.mainColor};
    color: ${theme.color.white};
    border: 1px solid ${theme.border.mainColor};
    font-weight: 600;
  }
  &.disabled {
    background-color: ${theme.background.grayf1f1f1};
    color: ${theme.color.gray808285};
    border: 1px solid ${theme.border.gray};
    padding: ${pixelToRem(11)} 0;
    font-size: ${pixelToRem(14)};
  }
`;

export default memo(Button);
