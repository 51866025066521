import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type BirthInputProps = {
  birth: string;
};

export const initialState: BirthInputProps = {
  birth: '',
};

const userBirthSlice = createSlice({
  name: 'birthInput',
  initialState,
  reducers: {
    onSetBirth: (state: BirthInputProps, action: PayloadAction<string>) => {
      state.birth = action.payload;
    },
    onReset: (state) => {
      state.birth = '';
    },
  },
});

export default userBirthSlice;
