/* eslint-disable implicit-arrow-linebreak */
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@reducer/store';

import { ResultTitle, ResultList, ListItem, ResultTotal, ItemWrap } from './style';

type ActionResultProps = {
  name: string;
  type: string;
};
type ArrayResultTypeProps = {
  type: string;
  title: string;
}[];

function ActionResult({ name, type }: ActionResultProps) {
  const actionTotal = useSelector((state: RootState) => state.actionTotal.totalPrices);
  const totalResult: ArrayResultTypeProps = [
    { type: 'payment', title: '결제' },
    { type: 'charge', title: '충전' },
    { type: 'usage', title: '소진' },
    { type: 'refund', title: '환불' },
  ];
  return (
    <>
      {name && <ResultTitle>{name} 검색 결과</ResultTitle>}
      {Object.keys(actionTotal).length > 0 && (
        <ResultList>
          <ItemWrap>
            {totalResult.map((arr, index) => (
              <ListItem key={index} className={type === arr.type ? 'active' : ''}>
                <li>{arr.title}내역</li>
                <li>
                  {actionTotal[arr.type].totalPrice.toLocaleString()}원 (
                  {actionTotal[arr.type].totalCount}건)
                </li>
              </ListItem>
            ))}
          </ItemWrap>
          {type === '' || type === 'action' ? null : (
            <>
              {totalResult.map(
                (arr, index) =>
                  type === arr.type && (
                    <ResultTotal key={index}>
                      <li>총 {arr.title} 내역</li>
                      <li>
                        {actionTotal[arr.type].totalPrice.toLocaleString()}원 (
                        {actionTotal[arr.type].totalCount}건)
                      </li>
                    </ResultTotal>
                  ),
              )}
            </>
          )}
        </ResultList>
      )}
    </>
  );
}

export default memo(ActionResult);
