import React from 'react';
import styled from 'styled-components';

import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';

interface RowProps {
  title: string;
  children?: React.ReactNode | React.ReactNode[];
}

export default function Row({ title, children }: RowProps) {
  return (
    <RowWrap>
      <RowTitle>{title}</RowTitle>
      <RowItems>{children ?? null}</RowItems>
    </RowWrap>
  );
}

const RowWrap = styled.div`
  width: 100%;
  padding-left: ${pixelToRem(27)};
  display: flex;
`;

const RowTitle = styled.span`
  width: ${pixelToRem(98)};
  padding-top: ${pixelToRem(11)};
  font-size: ${pixelToRem(15)};
  color: ${theme.color.lightBlack};
`;

const RowItems = styled.div`
  width: calc(100% - 98px);
  padding-right: ${pixelToRem(27)};
  display: flex;
  gap: 3px;
`;
