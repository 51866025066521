import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type ActionTotalProps = {
  totalPrices: any;
};
export const initialState: ActionTotalProps = {
  totalPrices: {},
};

const actionTotalSlice = createSlice({
  name: 'actionTotal',
  initialState,
  reducers: {
    setTotalList: (state, action: PayloadAction<ActionTotalProps>) => {
      state.totalPrices = action.payload;
    },
  },
});

export default actionTotalSlice;
