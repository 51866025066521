export const theme = {
  border: {
    white: '#fff',
    semiWhite: '#fafafc',
    black: '#000000',
    gray: '#ccc',
    mainColor: '#244895',
    lightGray: '#fafafc',
    gray707070: '#707070',
    gray808285: '#808285',
    grayf1f1f1: '#f1f1f1',
    grayc3c3c3: '#c3c3c3',
    lightBlack: '#363644',
    blue: '#356DE3',
    lightBlue: '#F1F3F6',
  },
  background: {
    white: '#fff',
    semiWhite: '#fafafc',
    black: '#000000',
    gray: '#ccc',
    mainColor: '#244895',
    lightGray: '#fafafc',
    gray707070: '#707070',
    gray808285: '#808285',
    grayf1f1f1: '#f1f1f1',
    grayc3c3c3: '#c3c3c3',
    lightBlack: '#363644',
    blue: '#356DE3',
    lightBlue: '#F1F3F6',
  },
  color: {
    white: '#fff',
    semiWhite: '#fafafc',
    black: '#000000',
    gray: '#ccc',
    mainColor: '#244895',
    lightGray: '#fafafc',
    gray707070: '#707070',
    gray808285: '#808285',
    grayf1f1f1: '#f1f1f1',
    grayc3c3c3: '#c3c3c3',
    lightBlack: '#363644',
    blue: '#356DE3',
    lightBlue: '#F1F3F6',
  },
};
