import { PostFileType } from '@util/initialData';

export type QnaType = {
  total: string;
  replied: string;
  noReplied: string;
};

export type QnaListType = {
  totalCount: number;
  qnas: {
    id: number;
    name: string;
    title: string;
    isPostFile: number;
    isReplied: number;
    createdAt: string;
  }[];
};

export const initialQnaList: QnaListType = {
  totalCount: 0,
  qnas: [],
};

export type QnaDetailType = {
  qna: {
    id: number;
    name: string;
    title: string;
    content: string;
    isReplied: number;
    isPostFile: number;
    createdAt: string;
    postFiles?: PostFileType[];
    reply?: string;
  };
};

export const initialQnaDetail: QnaDetailType = {
  qna: {
    id: -1,
    name: '',
    title: '',
    content: '',
    isReplied: 0,
    isPostFile: 0,
    createdAt: '',
  },
};
