import { useState, useCallback } from 'react';

export default function useModal() {
  const [isShow, setIsShow] = useState<boolean>(false);

  const onShow = useCallback(() => {
    setIsShow(true);
  }, []);

  const onNoShow = useCallback(() => {
    setIsShow(false);
  }, []);

  return { isShow, onShow, onNoShow };
}
