import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { withList, BasePropsType } from '@comp/common/wrapper/ListWrapper';
import PageTitle from '@comp/common/PageTitle';
import FilterItem from '@comp/common/FilterItem';
import Box from '@comp/common/Box';
import Table from '@comp/common/table/Table';
import Pagination from '@comp/common/Pagination';
import SearchDetailTableData from '@comp/search/SearchDetailTableData';
import { SearchDetailType, initialSearchDetail } from '@comp/search/SearchTypes';

import { FilterBox, ListLayout, InnerList, PageTitleWrap, SectionTitle } from '@src/layout';

import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';
import styled from 'styled-components';

function Base(props: BasePropsType<SearchDetailType>) {
  const { data, page, setQuery, onClickPage } = props;
  const { state }: { state: any } = useLocation();
  const navigate = useNavigate();

  const query = {
    page: `${page}`,
  };

  useEffect(() => {
    if (!state) {
      navigate('/search');
    }
    setQuery(query);
  }, [state, page]);

  const searchDetail = data || initialSearchDetail;

  return (
    <>
      <PageTitleWrap>
        <PageTitle>검색 상세 내역</PageTitle>
        <FilterBox>
          <FilterItem
            theadText='사용자'
            padding={`${pixelToRem(10)} ${pixelToRem(20)}`}
            margin={`0 0 ${pixelToRem(10)} 0`}
            radius={0}
          >
            <Box width={260} height={40}>
              <SearchDetailItemWrap>{searchDetail.name}</SearchDetailItemWrap>
            </Box>
          </FilterItem>
          <FilterItem
            theadText='조회기간'
            padding={`${pixelToRem(10)} ${pixelToRem(20)}`}
            margin={`0 0 ${pixelToRem(10)} 0`}
            radius={0}
          >
            <Box width={260} height={40}>
              <SearchDetailItemWrap>{searchDetail.createdAt}</SearchDetailItemWrap>
            </Box>
          </FilterItem>
          <FilterItem
            theadText='HASH 값'
            padding={`${pixelToRem(10)} ${pixelToRem(20)}`}
            margin={`0 0 ${pixelToRem(10)} 0`}
            radius={0}
          >
            <Box width={448} height={40}>
              <SearchDetailHash>{searchDetail.hash}</SearchDetailHash>
            </Box>
          </FilterItem>
          <FilterItem
            theadText='IP 주소'
            padding={`${pixelToRem(10)} ${pixelToRem(20)}`}
            margin={`0 0 ${pixelToRem(10)} 0`}
            radius={0}
          >
            <Box width={260} height={40}>
              <SearchDetailItemWrap>{searchDetail.ip}</SearchDetailItemWrap>
            </Box>
          </FilterItem>
        </FilterBox>
      </PageTitleWrap>
      <ListLayout>
        <SectionTitle>
          사용자 검색 목록 <span>({searchDetail.totalCount}건)</span>
        </SectionTitle>
        <InnerList>
          <Table>
            <SearchDetailTableData data={searchDetail} />
          </Table>
          <Pagination totalCount={searchDetail.totalCount} page={page} setPage={onClickPage} />
        </InnerList>
      </ListLayout>
    </>
  );
}

export default function SearchDetail() {
  return withList<SearchDetailType>(Base);
}

const SearchDetailItemWrap = styled.p`
  width: 100%;
  display: inline-block;
  line-height: ${pixelToRem(40)};
`;

const SearchDetailHash = styled(SearchDetailItemWrap)`
  color: ${theme.color.blue};
  overflow: hidden;
  text-overflow: ellipsis;
`;
