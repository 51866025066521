import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type PhoneInputProps = {
  phone: string;
};
export const initialState: PhoneInputProps = {
  phone: '',
};

const userPhoneSlice = createSlice({
  name: 'phoneInput',
  initialState,
  reducers: {
    onSetPhone: (state: PhoneInputProps, action: PayloadAction<string>) => {
      state.phone = action.payload;
    },
    onReset: (state) => {
      state.phone = '';
    },
  },
});

export default userPhoneSlice;
