import React, { memo, useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { ListLayout, FilterBox, InnerList, PageTitleWrap, SectionTitle } from '@src/layout';

import $axios from '@config/axios';

import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';
import { stringToQuery } from '@util/string';

import { ActionFilter } from '@comp/action/ActionTypes';
import PageTitle from '@comp/common/PageTitle';
import FilterItem from '@comp/common/FilterItem';
import ButtonContainer from '@comp/common/ButtonContainer';
import Button from '@comp/common/Button';
import Table from '@comp/common/table/Table';
import Pagination from '@comp/common/Pagination';

import ActionResult from '@comp/action/ActionResult';
import ActionTableData from '@comp/action/ActionTableData';

import { RootState } from '@reducer/store';
import actionListSlice from '@reducer/action/actionDataList';
import inputDataSlice from '@reducer/common/inputData';
import activeTabSlice from '@reducer/common/activeTab';
import actionFilterSlice from '@reducer/action/acionFilter';
import actionTotalSlice from '@reducer/action/actionResult';
import isSearchSlice from '@reducer/common/isSearch';

function getActionTitle(tab: string) {
  switch (tab) {
    case 'payment':
      return '결제';
    case 'charge':
      return '충전';
    case 'usage':
      return '소진';
    case 'refund':
      return '환불';
    default:
      return '전체 이용';
  }
}

const ActionList = () => {
  const dispatch = useDispatch();
  const { search, state }: { search: string; state: any } = useLocation();
  const navigate = useNavigate();

  const name = useSelector((state: RootState) => state.inputData.inputData);
  const tabName = useSelector((state: RootState) => state.actionFilter.tabName);
  const dataList = useSelector((state: RootState) => state.actionList.list);
  const isSearch = useSelector((state: RootState) => state.isSearch.isSearch);
  const [title, setTitle] = useState('전체 이용');
  const [totalCount, setTotalCount] = useState(0);
  const [userName, setUserName] = useState('');
  const [type, setType] = useState('');

  const fetchGetActionList = useCallback(
    async (query: { page?: number; type?: string; name?: string }, isRedirect = false) => {
      const q = stringToQuery(search) as {
        page: number;
        type: string;
        name: string;
        pageSize: number;
      };
      let queryString = '';
      let slicePath = '';
      if (Object.values(q).length > 0) {
        queryString = [`page=${q.page || 1}`, `name=${q.name}`, `type=${q.type}`].join('&');
        slicePath = `${q.type}`;
      }
      if (Object.values(query).length > 0) {
        queryString = [`page=${query.page || 1}`, `name=${query.name}`, `type=${query.type}`].join(
          '&',
        );
        slicePath = `${query.type}`;
      }
      if (isRedirect) {
        navigate(`/${tabName}?${queryString}`);
      }
      await $axios
        .get(`admin/action/total?${queryString}`)
        .then((res) => {
          if (res && res.data && res.data.status === 200) {
            const { data } = res.data;
            const { setTotalList } = actionTotalSlice.actions;
            const { setDataList } = actionListSlice.actions;
            dispatch(setDataList(data.histories));
            dispatch(setTotalList(data.totalPrices));
            setTotalCount(
              data.totalPrices[`${slicePath}` === '' ? 'action' : `${slicePath}`].totalCount,
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [name, tabName, search, setTotalCount, totalCount, dispatch, actionTotalSlice],
  );

  const onSearch = useCallback(() => {
    dispatch(isSearchSlice.actions.onSearch(true));
    fetchGetActionList({ name, type: tabName }, true);
    setUserName(name);
    setType(tabName);
    setTitle(getActionTitle(tabName));
  }, [fetchGetActionList]);

  const onPaging = useCallback(
    (page: number) => {
      fetchGetActionList({ name, type: tabName, page }, true);
    },
    [fetchGetActionList],
  );

  const onReset = useCallback(() => {
    dispatch(inputDataSlice.actions.onReset());
    dispatch(actionListSlice.actions.setReset());
    dispatch(actionFilterSlice.actions.onIsActive('전체'));
    dispatch(activeTabSlice.actions.setReset());
    dispatch(isSearchSlice.actions.onSearch(false));
    setType('action');
    setUserName('');
    navigate('/action');
    fetchGetActionList({});
    setTitle('전체 이용');
  }, []);

  useEffect(() => {
    if (state) {
      fetchGetActionList(state);
      setTitle(getActionTitle(state.type));
      setType(state.type);
      return;
    }
    onReset();
  }, [state]);

  return (
    <>
      <PageTitleWrap>
        <PageTitle />
        <FilterBox>
          <FilterItem hasInput={true} theadText='사용자' margin={`0 0 ${pixelToRem(10)} 0`} />
          <FilterItem theadText='상세조건' hasTab={true} />
        </FilterBox>
        <ButtonContainer padding={`${pixelToRem(20)} ${pixelToRem(27)}`}>
          <Button
            fontSize={16}
            width={133}
            padding={`${pixelToRem(10)} 0`}
            align={'center'}
            bgColor={`${theme.background.mainColor}`}
            fontColor={`${theme.color.white}`}
            borderColor={`1px solid ${theme.background.mainColor}`}
            margin={`0 ${pixelToRem(15)} 0 0`}
            onClick={onSearch}
            isActiveBtn={isSearch}
          >
            검색
          </Button>
          <Button
            fontSize={16}
            width={133}
            padding={`${pixelToRem(10)} 0`}
            align={'center'}
            bgColor={`${theme.background.white}`}
            borderColor={`1px solid ${theme.border.mainColor}`}
            fontColor={`${theme.color.mainColor}`}
            onClick={() => onReset()}
          >
            초기화
          </Button>
        </ButtonContainer>
      </PageTitleWrap>
      <ListLayout>
        <SectionTitle>
          {title} 목록 ( {totalCount.toLocaleString('ko')} 건 )
        </SectionTitle>
        <InnerList>
          <ActionResult name={userName} type={type} />
          <Table target={(stringToQuery(search) as ActionFilter).type || 'action'}>
            <ActionTableData
              type={(stringToQuery(search) as ActionFilter).type || 'action'}
              histories={dataList}
              totalCount={totalCount}
              page={+stringToQuery(search).page || 1}
            />
          </Table>
          <Pagination
            totalCount={totalCount}
            page={+stringToQuery(search).page || 1}
            setPage={onPaging}
          />
        </InnerList>
      </ListLayout>
    </>
  );
};

export default memo(ActionList);
