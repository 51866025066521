import React, { memo } from 'react';

import ButtonContainer from '@comp/common/ButtonContainer';
import Button from '@comp/common/Button';

import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';

import { ModalWrap, ModalBox, ModalText } from './style';

export type ModalProps = {
  isShow: boolean;
  h1Text?: string;
  children?: React.ReactNode | React.ReactNode[];
  firstBtn?: {
    isDisplay: boolean;
    content?: string;
    onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  };
  secondBtn?: {
    isDisplay: boolean;
    content?: string;
    onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  };
};

function Modal({
  isShow,
  h1Text,
  children,
  firstBtn = { isDisplay: true, content: '', onClick: () => {} },
  secondBtn = { isDisplay: true, content: '', onClick: () => {} },
}: ModalProps) {
  return (
    <>
      {isShow ? (
        <ModalWrap>
          <ModalBox>
            <ModalText>{h1Text}</ModalText>
            {children}
            <ButtonContainer display='flex' justifyContent='center'>
              {firstBtn.isDisplay && (
                <Button
                  margin={`0 ${pixelToRem(7)} 0 0`}
                  fontColor={`${theme.color.white}`}
                  padding={`${pixelToRem(11)} 0`}
                  fontSize={14}
                  width={133}
                  borderColor={`1px solid ${theme.border.mainColor}`}
                  bgColor={`${theme.background.mainColor}`}
                  onClick={firstBtn.onClick}
                >
                  {firstBtn.content}
                </Button>
              )}
              {secondBtn.isDisplay && (
                <Button
                  fontColor={`${theme.color.black}`}
                  padding={`${pixelToRem(11)} 0`}
                  fontSize={14}
                  width={133}
                  borderColor={`1px solid ${theme.border.gray}`}
                  onClick={secondBtn.onClick}
                >
                  {secondBtn.content}
                </Button>
              )}
            </ButtonContainer>
          </ModalBox>
        </ModalWrap>
      ) : null}
    </>
  );
}

export default memo(Modal);
