import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type ActiveTabState = {
  activeTab: string;
};

const initialState: ActiveTabState = {
  activeTab: '전체',
};

const activeTabSlice = createSlice({
  name: 'activeTab',
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<string>) => {
      state.activeTab = action.payload;
    },
    setReset: (state) => {
      state.activeTab = '전체';
    },
  },
});

export default activeTabSlice;
