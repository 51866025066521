import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';

type PaginationPropsType = {
  totalCount: number;
  page: number;
  setPage: (pageNumber: number) => void;
};

const buttonCountPerView = 5;

function Pagination({ totalCount, page, setPage }: PaginationPropsType) {
  const [nums, setNums] = useState(new Array(totalCount).fill(0).map((v, i) => i + 1));
  const [pageSize] = useState<number>(10);

  const onPage = (n: number) => () => {
    setPage(n);
  };

  const onFullPrevPage = () => {
    setPage(1);
  };

  const onFullNextPage = () => {
    setPage(Math.ceil(totalCount / pageSize));
  };

  useEffect(() => {
    const len = Math.ceil(totalCount / pageSize);
    if (len > 0) {
      const array = new Array(len).fill(0).map((v, i) => i + 1);
      setNums(array);
    } else {
      setNums([]);
    }
  }, [totalCount, pageSize]);
  // const onPrevPage = () => {
  //   if (page - buttonCountPerView < 1) {
  //     setPage(1);
  //     return;
  //   }
  //   setPage(page - buttonCountPerView);
  // };

  // const onNextPage = () => {
  //   if (page + buttonCountPerView > Math.ceil(totalCount / pageSize)) {
  //     setPage(Math.ceil(totalCount / pageSize));
  //     return;
  //   }
  //   setPage(page + buttonCountPerView);
  // };

  return (
    <PageNumList>
      {Math.ceil(totalCount / pageSize) > 5 && (
        <Arrow onClick={onFullPrevPage}>
          {page > 1 && page < 5 && (
            <IoIosArrowBack color={theme.color.grayc3c3c3} size={25} onClick={() => {}} />
          )}
        </Arrow>
      )}
      <div>
        {page % buttonCountPerView !== 0
          ? nums
              .slice(
                Math.floor(page / buttonCountPerView) * buttonCountPerView,
                Math.floor(page / buttonCountPerView) * buttonCountPerView + buttonCountPerView,
              )
              .map((v) => (
                <PageItem current={page === v} role='presentation' key={v} onClick={onPage(v)}>
                  {v}
                </PageItem>
              ))
          : nums
              .slice(
                Math.floor(page / buttonCountPerView) * buttonCountPerView - buttonCountPerView,
                Math.floor(page / buttonCountPerView) * buttonCountPerView,
              )
              .map((v) => (
                <PageItem current={page === v} role='presentation' key={v} onClick={onPage(v)}>
                  {v}
                </PageItem>
              ))}
      </div>
      {Math.ceil(totalCount / pageSize) > 5 && (
        <Arrow onClick={onFullNextPage}>
          {page < Math.ceil(totalCount / pageSize) && (
            <IoIosArrowForward color={theme.color.grayc3c3c3} size={25} onClick={() => {}} />
          )}
        </Arrow>
      )}
    </PageNumList>
  );
}

const PageNumList = styled.div`
  width: 100%;
  padding-top: ${pixelToRem(20)};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PageItem = styled.button<{ current: boolean }>`
  border: none;
  background: none;
  font-size: ${pixelToRem(17)};
  font-weight: 500;
  color: ${({ current }) => (current ? theme.color.blue : theme.color.gray808285)};
  margin: 0 ${pixelToRem(7)};
  padding: ${pixelToRem(5)};
  &.on {
  }
`;
const Arrow = styled.a`
  width: ${pixelToRem(25)};
  height: ${pixelToRem(25)};
  margin-left: ${pixelToRem(10)};
  cursor: pointer;
`;

export default Pagination;
