import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';
import { AiFillCaretDown } from 'react-icons/ai';

interface DropdownPropsType {
  options: string[];
  current: dayjs.Dayjs;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export default function Dropdown({ options, current, onClick }: DropdownPropsType) {
  const currentString = current.format('YYYY년 M월');
  const [isOpen, setIsOpen] = useState(false);
  const wrap = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (wrap.current) {
      const index = options.indexOf(currentString);
      if (index > 2) {
        wrap.current.scrollTo(0, 32 * (index - 2));
      }
    }
  }, [isOpen]);

  return (
    <DropdownWrap onClick={() => setIsOpen((state) => !state)}>
      <DropdownHeader>
        {currentString}
        <AiFillCaretDown size={16} />
      </DropdownHeader>
      {isOpen && (
        <div>
          <OptionWrap ref={wrap}>
            {options.map((option: string) => {
              return (
                <Option
                  key={option}
                  selected={option === currentString}
                  onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                    onClick(e);
                  }}
                >
                  {option}
                </Option>
              );
            })}
          </OptionWrap>
        </div>
      )}
    </DropdownWrap>
  );
}

const DropdownWrap = styled.div`
  width: ${pixelToRem(146)};
  height: ${pixelToRem(40)};
  padding: ${pixelToRem(9)} ${pixelToRem(16)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${theme.border.gray707070};
`;

const DropdownHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const OptionWrap = styled.div`
  position: relative;
  z-index: 999;
  width: ${pixelToRem(146)};
  height: ${pixelToRem(158)};
  margin-top: ${pixelToRem(8)};
  padding: 0 0 ${pixelToRem(16)} ${pixelToRem(16)};
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.border.gray707070};
  background-color: ${theme.background.white};
  overflow-y: scroll;
`;

const Option = styled.div<{ selected: boolean }>`
  position: relative;
  z-index: 9999;
  height: ${pixelToRem(24)};
  padding: ${pixelToRem(16)} 0;
  opacity: ${({ selected }) => (selected ? 1 : 0.3)};
  cursor: pointer;
`;
