/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import useModal from '@hook/useModal';

import { withEdit, BasePropsType } from '@comp/common/wrapper/EditWrapper';
import PageTitle from '@comp/common/PageTitle';
import FooterButton from '@comp/common/FooterButton';
import { FileButton } from '@comp/common/StyledButton';
import Row from '@comp/common/Row';
import Box from '@comp/common/Box';
import Modal from '@comp/common/modal/Modal';
import { initialQnaDetail, QnaDetailType } from '@comp/qna/QnaTypes';

import { PageTitleWrap } from '@src/layout';

import { onClickFileButton } from '@util/commonUtil';
import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';

function Base(props: BasePropsType<QnaDetailType>) {
  const { data, id, name, onClickList, onClickToCreate } = props;
  const qnaData = data ? data.qna : initialQnaDetail.qna;

  const [reply, setReply] = useState('');
  const { isShow, onShow, onNoShow } = useModal();

  const onChangeReply = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReply(e.target.value);
  }, []);

  const onReplyConfirmAlert = useCallback(() => {
    if (qnaData.isReplied === 1) {
      Swal.fire({
        icon: 'warning',
        text: '이미 답변이 달린 문의입니다.',
      });
      return;
    } else {
      onClickToCreate({
        qnaId: id,
        content: reply,
      });
    }
  }, [qnaData.isReplied, id, reply]);

  return (
    <>
      <Modal
        isShow={isShow}
        h1Text='문의에 대한 답변을 등록하시겠습니까?'
        firstBtn={{
          isDisplay: true,
          content: '확인',
          onClick: onReplyConfirmAlert,
        }}
        secondBtn={{ isDisplay: true, content: '취소', onClick: onNoShow }}
      ></Modal>
      <PageTitleWrap>
        <PageTitle>문의 답변</PageTitle>
        <ReplyHeader>
          <Row title='사용자'>
            <Box width={260} height={40}>
              {name}
            </Box>
          </Row>
          <Row title='등록일'>
            <Box width={260} height={40}>
              {qnaData.createdAt}
            </Box>
          </Row>
          <Row title='제목'>
            <Box height={40}>
              <QnaTitle>{qnaData.title}</QnaTitle>
            </Box>
          </Row>
          <Row title='내용'>
            <Box height={435}>
              <QnaContent>{qnaData.content}</QnaContent>
            </Box>
          </Row>
          <Row title='첨부파일'>
            {qnaData.postFiles &&
              qnaData.postFiles.map((file) => {
                return (
                  <FileButton
                    key={file.id}
                    type='button'
                    onClick={() => onClickFileButton(file.Bucket!, file.Key!, file.name)}
                  >
                    {file.name}
                  </FileButton>
                );
              })}
          </Row>
        </ReplyHeader>
        <ReplyBody>
          <Row title='관리자 답변'>
            {qnaData.isReplied ? (
              <Box height={435}>
                <QnaContent>{qnaData.reply || ''}</QnaContent>
              </Box>
            ) : (
              <ReplyContent value={reply} onChange={onChangeReply} />
            )}
          </Row>
        </ReplyBody>
        <FooterButton
          list={{ type: 'button', text: '목록', onClick: onClickList }}
          submit={!qnaData.isReplied ? { type: 'submit', text: '등록', onClick: onShow } : undefined}
        />
      </PageTitleWrap>
    </>
  );
}

export default function QnaReply() {
  return withEdit<QnaDetailType>(Base);
}

const ReplyHeader = styled.div`
  width: 100%;
  padding: ${pixelToRem(15)} 0 ${pixelToRem(9)} 0;
  display: flex;
  flex-direction: column;
  gap: ${pixelToRem(8)};
  border-bottom: 1px solid ${theme.border.gray};

  & > div:nth-child(4) {
    margin-bottom: ${pixelToRem(20)};
  }
  & > div:nth-child(5) {
    margin-bottom: ${pixelToRem(29)};
  }
`;

const QnaTitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${theme.color.blue};
`;

const QnaContent = styled.div`
  align-self: flex-start;
  padding-top: ${pixelToRem(13)};
  white-space: pre-wrap;
  line-height: ${pixelToRem(20)};
`;

const ReplyBody = styled.div`
  padding: ${pixelToRem(20)} 0;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${theme.border.gray};
`;

const ReplyContent = styled.textarea`
  width: 100%;
  height: 380px;
  resize: none;
  padding: ${pixelToRem(13)} ${pixelToRem(20)} 0 ${pixelToRem(20)};
  border: 1px solid ${theme.border.gray};
  line-height: ${pixelToRem(20)};
  font-size: ${pixelToRem(13)};
`;
