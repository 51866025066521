import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

import { initialMain, MainType } from '@comp/main/MainTypes';

import { useFetcher } from '@hook/useFetcher';

import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';
import { PageTitleWrap } from '@/src/layout';

interface TodayItemProps {
  title: string;
  data: { today: number; yesterday: number };
  unit: string;
}

function TodayItem({ title, data, unit }: TodayItemProps) {
  const { today, yesterday } = data;
  const getComparedEl = useCallback((compared: number, unit: string) => {
    if (compared === 0) {
      return <ComparedMsg>변동 없음</ComparedMsg>;
    }
    return (
      <ComparedMsg>
        <ComparedNumber compared={compared}>
          {compared > 0 ? '+' : '-'}
          {` ${Math.abs(compared).toLocaleString()}${unit}`}
        </ComparedNumber>{' '}
        어제 대비
      </ComparedMsg>
    );
  }, []);

  return (
    <ItemWrap>
      <ItemTitle>{title}</ItemTitle>
      <ItemAmount>
        {today.toLocaleString()} <ItemAmountUnit>{unit}</ItemAmountUnit>
      </ItemAmount>
      {getComparedEl(today - yesterday, unit)}
    </ItemWrap>
  );
}

export default function MainToday() {
  const [todayData, setTodayData] = useState<MainType['todayData']>(initialMain.todayData);
  const { data } = useFetcher('admin/main');

  useEffect(() => {
    if (data) {
      setTodayData(data.data.todayData);
    }
  }, [data]);

  const { report, noReport, charge, refund, search } = todayData;
  return (
    <Wrap>
      <h1>투데이</h1>
      <TodayGrid>
        <TodayItem title='방문자' data={{ today: 100, yesterday: 50 }} unit='명' />
        <TodayItem title='검색요청수' data={search} unit='건' />
        <TodayItem title='신고요청수' data={noReport} unit='건' />
        <TodayItem title='신고완료수' data={report} unit='건' />
        <TodayItem title='충전금액' data={charge} unit='원' />
        <TodayItem title='환불금액' data={refund} unit='원' />
      </TodayGrid>
    </Wrap>
  );
}

const Wrap = styled(PageTitleWrap)`
  flex-grow: 3;
  h1 {
    padding: ${pixelToRem(21)} ${pixelToRem(27)} ${pixelToRem(17)};
    font-weight: 500;
  }
`;

const TodayGrid = styled.div`
  padding: ${pixelToRem(20)} ${pixelToRem(27)};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${pixelToRem(21)} ${pixelToRem(27)};
  background-color: ${theme.background.white};
`;

const ItemWrap = styled.div`
  height: ${pixelToRem(160)};
  padding: ${pixelToRem(30)} 0 ${pixelToRem(10)} ${pixelToRem(30)};
  display: flex;
  flex-direction: column;
  gap: ${pixelToRem(15)};
  border: 1px solid ${theme.border.gray};
  border-radius: ${pixelToRem(6)};
  font-size: ${pixelToRem(20)};
`;

const ItemTitle = styled.span`
  font-weight: 500;
`;
const ItemAmount = styled.span`
  font-weight: 700;
`;

const ItemAmountUnit = styled.span`
  font-weight: 500;
`;

const ComparedMsg = styled.p`
  color: ${theme.color.gray808285};
  font-size: ${pixelToRem(15)};
`;

const ComparedNumber = styled.span<{ compared: number }>`
  color: ${({ compared }) => (compared > 0 ? '#009B0A' : '#F07070')};
  font-weight: 700;
`;
