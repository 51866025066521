export type Token = {
  id: number;
  name: string;
  email?: string;
  pn: string;
  iat: number;
  exp: number;
  isadmin: boolean;
  iss: string;
};

export function parseToken(token: string): Token {
  let jsonPayload = '';
  if (token) {
    const base64Url = token.split('.')[1]?.replace(/-/g, '+').replace(/_/g, '/');
    window
      .atob(base64Url as string)
      .split('')
      .forEach((c) => {
        jsonPayload += `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      });
    jsonPayload = decodeURIComponent(jsonPayload);
  }

  return JSON.parse(jsonPayload);
}

export default function validateToken(): boolean {
  const token = localStorage.getItem('adminToken');
  let isValid = false;
  if (token) {
    const { id, name, pn, iat, exp, isadmin, iss } = parseToken(token as string) as Token;
    if (!id || !(typeof id === 'number')) isValid = false;
    if (!name || !(typeof name === 'string')) isValid = false;
    if (!pn || !(typeof pn === 'string')) isValid = false;
    if (!(typeof isadmin === 'boolean')) isValid = false;

    if (!(typeof iss === 'string')) isValid = false;
    if (process.env.NODE_ENV === 'development' && iss !== 'localhost') isValid = false;
    if (process.env.NODE_ENV === 'production' && iss !== 'metu.ai') isValid = false;

    if (!(typeof iat === 'number')) isValid = false;

    if (!(typeof exp === 'number')) isValid = false;
    const now = Math.round(new Date().getTime() / 1000);
    if (now > exp) isValid = false;
    isValid = true;
  }
  if (!isValid) localStorage.removeItem('adminToken');
  return isValid;
}
