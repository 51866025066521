import React, { memo, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Button from '@comp/common/Button';
import { TableInfoList } from '@comp/common/table/TableHeadList';
import { RowBody } from '@comp/common/table/styles';
import { QnaListType } from '@comp/qna/QnaTypes';

import { theme } from '@util/theme';
import { pixelToRem } from '@util/commonStyles';

function QnaTableData({ qnas, query }: { qnas: QnaListType['qnas']; query: any }) {
  const navigate = useNavigate();
  const TableInfo = TableInfoList.qna;

  const onClickReplyButton = useCallback((id: number, name: string) => {
    navigate('/reply', { state: { id, name, query } });
  }, []);

  return (
    <>
      {qnas && qnas.length > 0 ? (
        qnas.map((qna) => {
          return (
            <RowBody tableInfo={TableInfo} key={qna.id}>
              <div>{qna.id}</div>
              <div>{qna.name}</div>
              <div>{qna.createdAt.slice(0, 10).replaceAll('-', '.')}</div>
              <div>
                <Link to={`${qna.id}`} state={{ id: qna.id, name: qna.name, query }}>
                  <QnaTitle id='title'>{qna.title}</QnaTitle>
                </Link>
              </div>
              <div>{qna.isPostFile ? '포함' : '미포함'}</div>
              <div>{qna.isReplied ? '답변완료' : '미답변'}</div>
              <ButtonWrap>
                {qna.isReplied ? (
                  <DisabledButton disabled>답변</DisabledButton>
                ) : (
                  <Button
                    width={85}
                    height={40}
                    padding={`0  ${pixelToRem(12)}`}
                    bgColor={`${theme.color.white}`}
                    borderColor={`1px solid ${theme.border.gray}`}
                    fontSize={13}
                    isHover
                    hoverBorder='none'
                    hoverBgColor={`${theme.background.mainColor}`}
                    hoverColor={`${theme.color.white}`}
                    onClick={() => onClickReplyButton(qna.id, qna.name)}
                  >
                    답변
                  </Button>
                )}
              </ButtonWrap>
            </RowBody>
          );
        })
      ) : (
        <RowBody tableInfo={TableInfo} className='empty'>
          내역이 없습니다.
        </RowBody>
      )}
    </>
  );
}
const QnaTitle = styled.span`
  &#title {
    color: ${theme.color.blue};
  }
`;

const DisabledButton = styled.button`
  width: ${pixelToRem(85)};
  height: ${pixelToRem(40)};
  border: 1px solid ${theme.border.gray};
  background-color: ${theme.background.lightGray};
  color: ${theme.color.gray707070};
  cursor: default;
`;

const ButtonWrap = styled.div`
  display: flex;
  gap: ${pixelToRem(7)};
`;

export default memo(QnaTableData);
